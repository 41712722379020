import { createRouter, createWebHistory } from 'vue-router'
import LoginView from "../views/LoginView.vue";
import HomeView from "../views/HomeView.vue";
import Stock from "../views/pages/Stock.vue";
import Stock2 from "../views/pages/Stock2.vue";
import Setting from "../views/pages/Setting.vue";
import Job from "../views/pages/Job.vue";
import Ledger from "../views/pages/Ledger.vue";
import global from "../Global.vue";
import AdminView from "@/views/admin/AdminView.vue";
import MapCreate from "../views/pages/map/MapCreate.vue";
import Map from "../views/pages/map/Map.vue";
import UserManage from "../views/pages/UserManage.vue";


const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "login",
      component: LoginView,
    },
    {
      path: "/admin",
      name: "admin",
      component: AdminView,
    },
    {
      path: "/home",
      component: HomeView,
      children: [
        {
          path: "/" + global.functionIdSTOCK + "/:id",
          name: "stock",
          component: Stock,
        },
        {
          path: "/" + global.functionIdSTOCK2 + "/:id",
          name: "stock2",
          component: Stock2,
          meta: { withoutDefaultWarehouse: true },
        },
        {
          path: "/:functionId/:id",
          name: "job",
          component: Job,
        },
        {
          path: "/:functionId/:id",
          name: "history",
          component: Job,
        },
        {
          path: "/" + global.functionIdMASTER + "/:id/:categorie",
          name: "ledger",
          component: Ledger,
        },
        {
          path: "/" + global.functionIdSETTINGS + "/:id",
          name: "setting",
          component: Setting,
          meta: { withoutDefaultWarehouse: true },
        },
        {
            path: '/map/:id/create/:menuId',
            component: MapCreate,
            meta: { withoutDefaultWarehouse: true }
        },
        {
            path: '/map/:id/edit/:menuId',
            component: Map,
            meta: { withoutDefaultWarehouse: true }
        },
        {
          path: "/" + global.functionIdUSERMANAGE,
          name: "userManage",
          component: UserManage,
          meta: { withoutDefaultWarehouse: true },
        }
      ],
    },
  ]
})

export default router
