export const listMenus = /* GraphQL */ `
  query ListMenus(
    $tenantId: ID
    $id: ModelIntKeyConditionInput
    $filter: ModelMenuFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMenus(
      tenantId: $tenantId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        stockStatus
        icon
        functionId
        locationFlag
        name
        showOrder
        showSide
        sendFinishFlag
        unexpectedTagFlag
        jobStep
        mapMode
        pickingRssi
        showDeleteButton
        configs {
          items {
            searchOrder
            source
            name
          }
        }
      }
      nextToken
    }
  }
`;

export const getMenu = /* GraphQL */ `
  query GetMenu($tenantId: ID!, $id: Int!) {
    getMenu(tenantId: $tenantId, id: $id) {
      name
      showDeleteButton
      configs {
        items {
          id
          name
          source
          searchOrder
          listOrder
          detailOrder
          sourceDefine {
            inputType
            items
          }
        }
      }
    }
  }
`;


export const listWarehouses = /* GraphQL */ `
  query ListWarehouses(
    $tenantId: ID
    $id: ModelIntKeyConditionInput
    $filter: ModelWarehouseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWarehouses(
      tenantId: $tenantId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        id
        name
        type
        width
        height
        real_width
        real_height
        default_shelf_height
        default_shelf_width
        rssi_threshhold
        state_change_interval
        all_shelves_scan_percentage
        remarks
        location_tag_filter
        item_tag_filter
        upload
        svg
        robot_home_x
        robot_home_y
        robot_stop_meters
        robot_stop_seconds
        robot_distance
        robot_orientation
        shelf_transparency
      }
    }
  }
`;
export const getWarehouse = /* GraphQL */ `
  query GetWarehouse($tenantId: ID!, $id: Int!) {
    getWarehouse(tenantId: $tenantId, id: $id) {
      tenantId
      id
      name
      width
      height
      real_width
      real_height
      default_shelf_height
      default_shelf_width
      rssi_threshhold
      state_change_interval
      all_shelves_scan_percentage
      remarks
      location_tag_filter
      item_tag_filter
      upload
      svg
      robot_home_x
      robot_home_y
      robot_stop_meters
      robot_stop_seconds
      robot_distance
      robot_orientation
      shelf_transparency
    }
  }
`;
export const listTenants = /* GraphQL */ `
  query ListTenants(
    $tenantId: ID
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTenants(
      tenantId: $tenantId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        remarks
        encode_type
        systemId
        item_tag_filter
        location_tag_filter
        show_all_warehouse
      }
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $tenantId: ID
    $id: ModelIntKeyConditionInput
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLocations(
      tenantId: $tenantId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        label
        warehouseId
        number
      }
      nextToken
    }
  }
`;
export const listLocationTags = /* GraphQL */ `
  query ListLocationTags(
    $tenantId: ID
    $id: ModelIntKeyConditionInput
    $filter: ModelLocationTagFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLocationTags(
      tenantId: $tenantId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        epc
        location_id
      }
      nextToken
    }
  }
`;
export const getTenant = /* GraphQL */ `
  query GetTenant($tenantId: ID!) {
    getTenant(tenantId: $tenantId) {
      tenantId
      remarks
      encode_type
      location_tag_filter
      item_tag_filter
      duplicate_itemCode
      group
      systemId
      ip_check
      show_all_warehouse
      tecTagFlag
      barcode_input_all_flag
      additional_filter
      customer_masters
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const getJobEpcList = /* GraphQL */ `
  query GetJobEpcList($conditions: AWSJSON) {
    getJobEpcList(conditions: $conditions){
      epc
      itemCode
      locationId
      serial
      updatedAt
    }
  }
`;
export const getStockDetailList = /* GraphQL */ `
  query GetStockDetailList($conditions: AWSJSON) {
    getStockDetailList(conditions: $conditions){
      epc
      itemCode
      locationId
      serial
      updatedAt
    }
  }
`;

export const searchStocks = /* GraphQL */ `
  query SearchStocks($conditions: AWSJSON) {
    searchStocks(conditions: $conditions)
  }
`;

export const searchAllStocks = /* GraphQL */ `
  query SearchAllStocks($conditions: AWSJSON) {
    searchAllStocks(conditions: $conditions)
  }
`;

export const searchJobs = /* GraphQL */ `
  query SearchJobs($conditions: AWSJSON) {
    searchJobs(conditions: $conditions)
  }
`;

export const searchHistory = /* GraphQL */ `
  query searchHistory($conditions: AWSJSON) {
    searchHistory(conditions: $conditions)
  }
`;

export const queryItemMasterHeaders = /* GraphQL */ `
  query QueryItemDefines(
    $tenantId: ID!
  ) {
    queryItemDefines(
      tenantId: $tenantId
      sourceTable: {eq: "ItemMaster"}
    ) {
      items {
        name
        source
        order
        inputType
      }
      nextToken
    }
  }
`;

export const queryHeadersWithTable = /* GraphQL */ `
  query QueryItemDefines(
    $tenantId: ID!
    $sourceTable: String!
  ) {
    queryItemDefines(
      tenantId: $tenantId
      sourceTable: {eq: $sourceTable}
    ) {
      items {
        name
        source
        order
        inputType
      }
      nextToken
    }
  }
`;

export const queryLocationsByWarehouseId = /* GraphQL */ `
  query QueryLocationsByWarehouseId(
    $tenantId: ID!
    $warehouseId: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryLocationsByWarehouseId(
      tenantId: $tenantId
      warehouseId: $warehouseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        warehouseId
        x
        y
        width
        height
        rotation
        label
        number
      }
      nextToken
    }
  }
`;


export const getItemMasterList = /* GraphQL */ `
  query GetItemMasterList($conditions: AWSJSON) {
    getItemMasterList(conditions: $conditions) 
  }
`;

export const getJobDetailList = /* GraphQL */ `
  query GetJobDetailList($jobId: Int, $flag: Boolean, $limit: Int
    $offset: Int) {
    getJobDetailList(jobId: $jobId, flag: $flag, limit: $limit
      offset: $offset) {
          id
          itemCode
          expectAmount
          itemMaster
        
    }
  }
`;

export const getProcessJob = /* GraphQL */ `
  query GetProcessJob($tenantId: ID!, $jobKey: String!) {
    getProcessJob(tenantId: $tenantId, jobKey: $jobKey) {
      tenantId
      jobKey
      status
      seconds
      message
      group
      createdAt
      updatedAt
    }
  }
`;

export const getAddableItems = `
  query GetAddableItems($tableName: String!) {
    getAddableItems(tableName: $tableName)
}
`;

export const checkIp = /* GraphQL */ `
  query CheckIp {
    checkIp
  }
`;

export const queryItemDefines = /* GraphQL */ `
  query QueryItemDefines(
    $tenantId: ID!
    $sourceTable: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelItemDefineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryItemDefines(
      tenantId: $tenantId
      sourceTable: $sourceTable
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        source
        name
        inputType
        showOnApp
        searchOnApp
        order
        items
      }
      nextToken
    }
  }
`;
export const queryMenuConfigsByMenuId = /* GraphQL */ `
  query QueryMenuConfigsByMenuId(
    $tenantId: ID!
    $menuId: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMenuConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryMenuConfigsByMenuId(
      tenantId: $tenantId
      menuId: $menuId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        source
        searchOrder
        listOrder
        detailOrder
      }
      nextToken
    }
  }
`;
export const listItemDefines = /* GraphQL */ `
  query ListItemDefines(
    $tenantId: ID
    $source: ModelStringKeyConditionInput
    $filter: ModelItemDefineFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listItemDefines(
      tenantId: $tenantId
      source: $source
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        source
        name
        inputType
        items
        sourceTable
        showOnApp
        searchOnApp
        order
      }
      nextToken
    }
  }
`;

export const queryLocationTagsByWarehouseId = /* GraphQL */ `
  query QueryLocationTagsByWarehouseId(
    $tenantId: ID!
    $warehouseId: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLocationTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryLocationTagsByWarehouseId(
      tenantId: $tenantId
      warehouseId: $warehouseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        warehouseId
        location_id
        epc
        x
        y
      }
      nextToken
    }
  }
`;
export const listMenuConfigs = /* GraphQL */ `
  query ListMenuConfigs(
    $tenantId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelMenuConfigFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMenuConfigs(
      tenantId: $tenantId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        id
        source
        group
      }
      nextToken
    }
  }
`;

export const listAppItemConfigs = /* GraphQL */ `
  query ListAppItemConfigs(
    $tenantId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelAppItemConfigFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAppItemConfigs(
      tenantId: $tenantId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        id
        screenId
        source
        name
        sourceDefine {
          tenantId
          source
          name
          inputType
          items
          sourceTable
          showOnApp
          searchOnApp
          order
          group
          createdAt
          updatedAt
        }
        order
        group
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getStockAmount = /* GraphQL */ `
  query GetStockAmount($conditions: AWSJSON) {
    getStockAmount(conditions: $conditions)
  }
`;

export const getItemDefine = /* GraphQL */ `
  query GetItemDefine($tenantId: ID!, $source: String!) {
    getItemDefine(tenantId: $tenantId, source: $source) {
      tenantId
      source
      name
      inputType
      items
      sourceTable
      showOnApp
      searchOnApp
      order
    }
  }
`;

export const listUsersInGroup = /* GraphQL */ `
  query ListUsersInGroup($group: String) {
    listUsersInGroup(group: $group) {
      username
      createdAt
      updatedAt

    }
  }
`;
export const getUserManage = /* GraphQL */ `
  query GetUserManage($tenantId: ID!, $username: String!) {
    getUserManage(tenantId: $tenantId, username: $username) {
      tenantId
      username
      menuId
      accessLevel
      group
    }
  }
`;
export const listUserManages = /* GraphQL */ `
  query ListUserManages(
    $tenantId: ID
    $username: ModelStringKeyConditionInput
    $filter: ModelUserManageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserManages(
      tenantId: $tenantId
      username: $username
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        username
        menuId
        accessLevel
        group
      }
    }
  }
`;
export const getCustomerMaster = /* GraphQL */ `
  query GetCustomerMaster($tableName: String!) {
    getCustomerMaster(tableName: $tableName)
  }
`;