<template>
<table-detail :headers="headers" :items="currentData" :paginate="paginate" :sort="sortBy" :title="title" :loading="loading" @row-selected="rowSelected" @un-row-selected="unRowSelected"  @updatePage="updatePage" @sortChange="sortChange" name="table-simple-modal-item">
    <template v-slot:back>
        <span style="
                    font-weight: 600;
                    display: inline-block;
                    padding: 1rem;
                    padding-left: 0.5rem;
                    cursor: pointer;
                " @click="$emit('backToMainPage')">
            &lt;&lt; back</span> </template> </table-detail>
</template>

<script>
import TableDetail from "../table/TableDetail.vue";
import * as queries from "../../graphql/locus_queries";
import AwsApi from "../../Api"
export default {
    props: ["infos", "tenantId", "url", "title", "form", "formItems"],
    data() {
        return {
            headers: [],
            items: [],
            count: null,
            paginate: {},
            page: 1,
            loading: false,
            sortBy: [],
            direction: null,
            limit: 1000,
            offset: 0,
            allData: [],
            currentData: [],
            count: 30,
            page: 1,
        };
    },
    components: {
        TableDetail,
    },
    mounted() {
        this.clearData();
        this.fetchMenuConfigs()
        this.getListData()
    },
    methods: {
        async fetchMenuConfigs() {
            const response = await AwsApi.graphql({
                query: queries.getMenu,
                variables: {
                    tenantId: this.tenantId,
                    id: this.url.menu_id
                }
            }, this);
            if (response === undefined) {
                return
            }
            this.displayTitle = response.data.getMenu.name
            this.menuConfigs = response.data.getMenu.configs.items
            if (this.menuConfigs.length === 0) {
                return
            }
            this.setTableFields(this.menuConfigs);
        },
        setTableFields(menuConfigs) {
            this.headers =
                menuConfigs
                .filter(item => item.detailOrder && item.detailOrder !== -1)
                .sort((a, b) => a.detailOrder - b.detailOrder)

            // this.sortBy = [{
            //     direction: 'asc',
            //     source: showHeaders[0].source
            // }];

        },
        async getListData() {
            var myInit = {};
            for (const key in this.form) {
                const hasKey = this.formItems.some(i => i.options === key)
                const isDate = this.formItems.some(i => i.sourceDefine && (i.sourceDefine.inputType === 2 || i.sourceDefine.inputType === 4 || i.sourceDefine.inputType === 6) && i.options === key)
                if (hasKey) {
                    if (this.form[key] !== null && this.form[key] !== '') {
                        //myInit.body[key] = this.form[key]
                       if (typeof (this.form[key]) === "boolean") {
                            if (this.form[key] === true) {
                                myInit[key] = this.form[key]
                            }
                        } else  if (typeof (this.form[key]) == "string" && !isDate) {
                            myInit[key] = "%" + this.form[key] + "%"
                        } else {
                            myInit[key] = this.form[key]
                        }

                    }
                }
            }
            myInit = {
                ...myInit,
                menuId: this.url.menu_id,
                limit: this.limit,
                offset: this.offset,
                warehouseId: this.url.warehouse_id,
                searchType: 1,
                ...this.infos
            };
            if (this.title === "Stocks") {
                const response = await AwsApi.graphql({
                    query: queries.searchStocks,
                    variables: {
                        conditions: JSON.stringify(myInit)
                    }
                }, this)
                if (response === undefined) {
                    return
                }
                const convertData = response.data.searchStocks.map(item => {
                    return JSON.parse(item)
                })
                this.allData = this.allData.concat(convertData);
                this.setData();

            } else {
                const response = await AwsApi.graphql({
                    query: queries.searchJobs,
                    variables: {
                        conditions: JSON.stringify(myInit)
                    }
                }, this)
                if (response === undefined) {
                    return
                }
                const convertData = response.data.searchJobs.map(item => {
                    return JSON.parse(item)
                })
                this.allData = this.allData.concat(convertData);
                this.setData();
            }

        },
        setData() {
            this.sortData();
            this.currentData = this.allData.slice(
                (this.page - 1) * this.count,
                this.page * this.count
            );
            this.currentData = this.currentData.map(item => {
                return {
                    ...item,
                    'JobEpc.locationId': item['locationName'],
                    'Stock.locationId': item['locationName'],
                    'Stock.warehouseId': item['warehouseName'],
                    'Job.warehouseId': item['warehouseName'],
                    'JobEpc.warehouseId': item['warehouseName'],
                    'ItemMaster.warehouseId': item['warehouseName']
                }
            })

            this.paginate = {
                total: this.allData.length,
                per_page: this.count,
                current_page: this.page,
            };
        },
        rowSelected(item) {
            // change property item_location_id to be able to select location on floor-map (locationId property)
            item.item_location_location_id = String(item.location_id);
            this.$emit("selectRow", item);
        },
        unRowSelected(item) {
            this.$emit("unSelectRow", item);
        },
        // ----- Paginate
        updatePage(page) {
            this.page = page;
            if (this.page * this.count >= this.limit + this.offset) {
                this.offset += this.limit;
                this.getListData()
            } else {
                this.setData();
            }
        },
        // ----- Sort

        sortChange(value) {
            //check if value already in array
            const exist = this.sortBy.map((item) => item.source).includes(value);
            // store the new value if not exist or change direction
            this.sortBy = exist ?
                this.sortBy.map((item) => {
                    if (item.source === value) {
                        item.direction =
                            item.direction === "asc" ? "desc" : "asc";
                    }
                    return item;
                }) : [{
                    source: value,
                    direction: "asc",
                }, ];
            this.setData();
        },
        sortData() {
            if (this.sortBy.length === 0) {
                return;
            }
            this.allData.sort((a, b) => {
                const sort = this.sortBy[0];
                if (sort.direction === "asc") {
                    return a[sort.source] < b[sort.source] ? -1 : 1;
                } else {
                    return a[sort.source] > b[sort.source] ? -1 : 1;
                }
            });
        },
        clearData() {
            this.allData = [];
            this.currentData = [];
            this.page = 1;
            this.paginate = {};
            this.offset = 0;
        },
    },
};
</script>

<style scoped>
.modal-item-header {
    display: flex;
    justify-content: space-around;
}

.modal-item-header-key {
    font-weight: 600;
}

.modal-item-header-value {
    display: inline-block;
    margin-left: 4px;
    margin-right: 1rem;
}
</style>
