<script>
const userName = "userName";
const tenantId = "tenantId";

const localUserName = "localUserName";
const localPassword = "localPassword";

const isGuest = "isGuest";

const functionIdSTOCK = 0;
const functionIdJOB = 1;
const functionIdLINK = 2;
const functionIdMASTER = 3;
const functionIdSETTINGS = 4;
const functionIdSTOCK2 = 5;
const functionIdHISTORY = 6;
const functionIdSEARCHMAP = 7;

const functionIdUSERMANAGE = 99;

const showAPP = 2;
const showWEB = 1;

const apiName = "locusmappingv2Api";

const userManageFlag = false;

//const jobsSearch="/jobs/search";
//const master="/master";
//const stock="/stock";
function formatLongDate() {
    const date = new Date()
    let myyear = date.getFullYear();
    let mymonth = date.getMonth() + 1;
    let myweekday = date.getDate();
    let myHour = date.getHours();
    let myMin = date.getMinutes();
    let mySec = date.getSeconds();

    if (mymonth < 10) {
        mymonth = '0' + mymonth;
    }
    if (myweekday < 10) {
        myweekday = '0' + myweekday;
    }
    if (myHour < 10) {
        myHour = '0' + myHour;
    }
    if (myMin < 10) {
        myMin = '0' + myMin;
    }
    if (mySec < 10) {
        mySec = '0' + mySec;
    }
    return myyear + '' + mymonth + '' + myweekday + '' + myHour + '' + myMin + '' + mySec;
}

export default {
    userName,
    tenantId,
    localUserName,
    localPassword,
    isGuest,
    functionIdSTOCK,
    functionIdJOB,
    functionIdLINK,
    functionIdMASTER,
    functionIdSETTINGS,
    functionIdSTOCK2,
    functionIdHISTORY,
    functionIdSEARCHMAP,
    functionIdUSERMANAGE,
    showAPP,
    showWEB,
    apiName,
    formatLongDate,
    userManageFlag
    //jobsSearch,
    //master,
    //stock

}
</script>
