<template>
<section style="min-height: 500px">
    <b-overlay :show="showOverlay" rounded="lg" no-center="true">
        <template #overlay>
            <div class="text-center" style="margin-top: 300px;">
                <b-spinner v-if='csvUploadMessage === $t("csv_upload_loading")' style="width: 3rem; height: 3rem;margin-bottom:1rem" label="Large Spinner"></b-spinner>
                <p id="cancel-label">{{ csvUploadMessage }}</p>
                <b-button v-if='csvUploadMessage !== $t("csv_upload_loading")' class="btn green-rflocus" size="sm" @click="showOverlay = false">
                    OK
                </b-button>
            </div>
        </template>
        <b-nav justified tabs>
            <b-nav-item :active="categorie.isActive" :disabled="isBusy" @click="setCategorieActive(categorie)" v-bind:key="categorie.id" v-for="categorie of categories">{{ categorie.name }}
            </b-nav-item>
        </b-nav>

        <page-header v-if="categories[0].isActive && formItems.length !== 0" :title="displayTitle" style="-webkit-user-select: none; /* Safari */">
            <app-form ref="ledgerForm" :items="formItems" :working="isBusy" :csvWorking="isCsvBusy" @fetchSearch="submitFormSearch" :form_select_options="form_select_options" @fetchCsv="downloadCSV" />
        </page-header>

        <b-form-select v-if="categories[2].isActive" v-model="warehouseSelect" :options="listWarehouses" class="mb-3" />

        <b-table :busy="loading" borderless striped responsive :fields="headers" thead-tr-class="ledger_main_table_th" :items="currentData" tbody-tr-class="pointer">
            <template v-slot:cell(epc)="data">
                <div v-if="data.value !== undefined && data.value !== null && data.value.length !== 0">
                    <span>{{ data.value.join(',') }}</span>
                </div>
            </template>
            <template v-slot:cell(map)="data">
                <div class="d-flex ">
                    <div v-if="data.item.upload !== undefined && data.item.upload !== null" style="margin-right: 0.25rem !important;">
                        <ButtonEdit @click="$router.push(`/map/${data.item.id}/edit/${this.$route.params.id}?warehouse=${this.$route.query.warehouse}`)">
                            <img v-if="data.item.imageLoadFlag" :src="data.item.signedURL" class="map-button" width="40" height="30" />
                            <i v-else class="fa fa-map"></i>
                        </ButtonEdit>
                    </div>
                    <div v-else style="margin-right: 0.25rem !important;">
                        <ButtonAdd class="create_label" @click="$router.push(`/map/${data.item.id}/create/${this.$route.params.id}?warehouse=${this.$route.query.warehouse}`)">新規</ButtonAdd>
                    </div>
                    <ButtonAdd>
                        <label class="upload_csv_label" :for="`mapfile${data.index}`">
                            <i class="fa fa-cloud-upload m-r-5"></i>
                            <span>導入</span>
                        </label>
                    </ButtonAdd>
                    <input type="file" :id="`mapfile${data.index}`" multiple @change="handleMapFileUpload($event, data.item.id, data.index)" hidden />

                </div>
            </template>
            <template v-slot:cell(robot_orientation)="data">
                <span v-if="data.value !== undefined && data.value !== null">{{ $t(`robot_orientation.${data.value.toString()}`) }}</span>
            </template>
        </b-table>
        <div class="row">
            <div class="col-12">
                <b-button v-if="!categories[2].isActive" class="btn green-rflocus mr-1" @click="openModalCreateRow()">
                    <i class="fa fa-plus-square"></i> {{ $t("button.create") }}
                </b-button>
                <b-button class="btn green-rflocus mr-1" @click="openModalUpdateRow()">
                    <i class="fa fa-plus-square"></i> {{ $t("button.update") }}
                </b-button>
                <b-button v-if="!(categories[1].isActive || categories[2].isActive) " size="sm" class="btn green-rflocus mr-1">
                    <label class="upload_csv_label" for="file">
                        <i class="fa fa-cloud-upload m-r-5"></i>
                        <span>csvファイル一括登録</span>
                    </label>
                </b-button>
                <b-button v-if="categories[0].isActive" class="btn green-rflocus mr-1" @click="onClickDeleteAll()">
                    <i class="fa fa-trash mr-1"></i>{{ $t("button.delete_all") }}
                </b-button>
                <input type="file" id="file" ref="file" accept=".csv, .xlsx, .xlsm" v-on:change="handleFileUpload()" hidden />

                <b-pagination align="center" size="sm" :disabled="isBusy" :total-rows="pagination.totalRow" v-model="currentPage" :per-page="pagination.perPage"></b-pagination>
            </div>
        </div>

        <!-- <modal-setting> -->
        <b-modal v-model="ledgerModal" size="xxl" ref="ledgerModal" @hide="removeNewItems">

            <!-- !header-->
            <b-table ref="ledgerTable" :fields="modalHeaders" :items="modalItemData" hover :selectable="true" responsive :select-head="false" @rowSelected="rowSelected" @rowUnselected="rowUnselected">

                <template v-slot:head()="data">
                    <template v-if="categories[0].isActive && data.label === '選択'">
                        <b-form-checkbox v-model="selectAll" @change="onclickSelectAll()"></b-form-checkbox>
                    </template>
                    <template v-else>{{data.label}}</template>
                </template>

                <template v-slot:cell(delete)="data">
                    <b-form-checkbox v-model="selection" :value="data.item"></b-form-checkbox>
                </template>

                <template v-slot:cell()="data">

                    <template v-if="currentSelect !== null && currentSelect.id === data.item.id">
                        <b-form-input size="sm" :modelValue="data.item[data.field.key]" @input="updateItem($event, data.item, data.field.key)" type="search"></b-form-input>
                    </template>

                    <template v-else>
                        <span>{{data.item[data.field.key]}}</span>
                    </template>

                </template>

                <template v-slot:cell(id)="data">
                    <span>{{data.item.id}}</span>
                </template>

                <template v-slot:cell(warehouseId)="data">
                    <span>{{data.item.warehouseId}}</span>
                </template>
                <template v-slot:cell(epc)="data">
                    <div v-if="data.value !== undefined && data.value !== null && data.value.length !== 0">
                        <span>{{ data.value.join(',') }}</span>
                    </div>
                </template>
                <template v-slot:cell(searchFinishedFlag)="data">
                    <b-form-checkbox id="checkbox-1" v-model="data.value" name="checkbox-1" @change="updateItem($event, data.item, data.field.key)"></b-form-checkbox>
                </template>
                <template v-slot:cell(robot_orientation)="data">
                    <b-form-select v-model="data.value" :options="[
                            { value: 0, text: 'UP'},
                            { value: 1, text: 'LEFT' },
                            { value: 2, text: 'DOWN' },
                            { value: 3, text: 'RIGHT' }]" class="mb-3" @change="updateItem($event, data.item, data.field.key)" />
                </template>
                <template v-slot:cell(number)="data">

                    <template v-if="currentSelect !== null && currentSelect.id === data.item.id">
                        <b-form-input :modelValue="data.item[data.field.key]" type="number" @input="updateItem($event, data.item, data.field.key)"></b-form-input>
                    </template>

                    <template v-else>
                        <span>{{data.item[data.field.key]}}</span>
                    </template>
                </template>

            </b-table>
            <template v-slot:footer>
                <div class="w-100 d-flex justify-content-between">
                    <ButtonAdd v-if="modalCreate" @click="onclickAddItem"></ButtonAdd>
                    <div v-else></div>
                    <div>
                        <button v-if="categories[1].isActive" type="button" class="btn btn-outline-success btn-rounded w-md waves-effect waves-light mr-2" @click="downloadWarehouseCsv" :disabled="csvWorking">
                            <template v-if="csvWorking">
                                <i class="fa fa-spin fa-hourglass m-r-5"></i>
                                処理中
                            </template>
                            <template v-else>
                                <i class="fa fa-cloud-download m-r-5"></i>
                                <span>csv出力</span>
                            </template>
                        </button>
                        <div v-else></div>
                        <ButtonSave @click="onclickItemUpdate"></ButtonSave>
                        <ButtonDelete @click="onclickItemDelete"></ButtonDelete>
                    </div>
                </div>
            </template>
        </b-modal>

        <b-modal title="Delete Master" v-model="modalAlertDeleteMaster" size="s" @ok="deleteMasterDataFun">
            <p>台帳データ全件が削除されます。よろしいですか？</p>
        </b-modal>

        <b-modal title="Delete Warehouse" v-model="modalAlertDeleteWarehouse" size="s" @ok="deleteDataFun">
            <p>以下のマップが削除されます。宜しいでしょうか？</p>
            <p>マップID：</p>
            <p>{{ selection.map((item) => item.id).join(", ") }}</p>
        </b-modal>

        <b-modal title="Delete Location" v-model="modalAlertDeleteLocation" size="s" @ok="deleteDataFun">
            <p>以下のロケーションが削除されます。宜しいでしょうか？</p>
            <p>ロケーション：</p>
            <p>{{ selection.map((item) => item.label).join(", ") }}</p>
        </b-modal>
    </b-overlay>
</section>
</template>

<style>
.map-button {
    vertical-align: text-bottom;
}
</style>

<script>
import ButtonAdd from "../../components/buttons/ButtonAdd.vue";
import ButtonDelete from "../../components/buttons/ButtonDelete.vue";
import ButtonSave from "../../components/buttons/ButtonSave.vue";
import * as queries from "../../graphql/locus_queries";
import * as mutations from "../../graphql/locus_mutations";
import global from "../../Global.vue";
import Form from '../../components/form/Form.vue'
import PageHeader from '../layout/PageHeader.vue'
import AwsApi from "../../Api"
import {
    v4 as uuidv4
} from "uuid"

import {
    API,
    graphqlOperation,
    Storage
} from "aws-amplify";
import {
    onUpdateProcessJob
} from "../../graphql/locus_subscriptions";

export default {
    components: {
        ButtonSave,
        ButtonDelete,
        ButtonAdd,
        'app-form': Form,
        PageHeader
    },
    data() {
        return {
            tenantId: "",
            limit: 1000,
            offset: 0,
            allData: [],
            currentData: [],
            pagination: {
                totalRow: 0,
                perPage: 0
            },
            currentPage: 1,
            count: 15,
            isBusy: false,
            isCsvBusy: false,
            modalCreate: false,
            warehouseSelect: null,
            listWarehouses: [],
            categories: [{
                    label: "item",
                    name: "品目",
                    isActive: true,
                    header: [
                        //     {
                        //     key: "itemCode",
                        //     label: this.$t("item_code"),
                        //     id: 0,
                        // }
                    ],
                    url: {
                        delete: mutations.deleteItemMaster
                    }
                },
                {
                    label: "warehouse",
                    name: "マップ",
                    isActive: false,
                    header: [{
                        key: "id",
                        label: "id",
                        thStyle: {
                            "min-width": '100px'
                        }
                    }, {
                        key: "name",
                        label: this.$t("warehouse_name"),
                        thStyle: {
                            "min-width": '200px'
                        }
                    }, {
                        key: "item_tag_filter",
                        label: "item_tag_filter",
                        thStyle: {
                            "min-width": '300px'
                        }
                    }, {
                        key: "location_tag_filter",
                        label: "location_tag_filter",
                        thStyle: {
                            "min-width": '200px'
                        }
                    }, {
                        key: "map",
                        label: "マップ",
                        thStyle: {
                            "min-width": '180px'
                        }
                    }, {
                        key: "remarks",
                        label: "remarks",
                        thStyle: {
                            "min-width": '100px'
                        }
                    }, {
                        key: "rssi_threshhold",
                        label: "location_tag_rssi_threshold",
                    }, {
                        key: "state_change_interval",
                        label: "state_change_interval",
                    }, {
                        key: "all_shelves_scan_percentage",
                        label: "all_shelves_scan_percentage",

                    }, {
                        key: "robot_home_x",
                        label: "robot_home_x",

                    }, {
                        key: "robot_home_y",
                        label: "robot_home_y",

                    }, {
                        key: "robot_stop_meters",
                        label: "robot_stop_meters",

                    }, {
                        key: "robot_stop_seconds",
                        label: "robot_stop_seconds",

                    }, {
                        key: "robot_distance",
                        label: "robot_distance",

                    }, {
                        key: "robot_orientation",
                        label: "robot_orientation",

                    }, {
                        key: "shelf_transparency",
                        label: "shelf_transparency",

                    }],
                    url: {
                        delete: mutations.deleteWarehouse
                    }
                },
                {
                    label: "location",
                    name: "ロケーション",
                    isActive: false,
                    header: [{
                            key: "number",
                            label: this.$t("number"),
                        }, {
                            key: "label",
                            label: this.$t("location")
                        },
                        {
                            key: "warehouseId",
                            label: `${this.$t("warehouse")}`,
                        }, {
                            key: "epc",
                            label: this.$t("epc"),
                        },
                    ],
                    url: {
                        delete: mutations.deleteLocation
                    }
                }
            ],
            headers: [],
            selection: [],
            modalItemData: [],
            tmpId: 0,
            reader: new FileReader(),
            updateCsvPath: "",
            form: null,
            formItems: [],
            form_select_options: {},
            displayTitle: "検索",
            loading: false,
            showOverlay: false,
            csvUploadMessage: this.$t("csv_upload_loading"),
            ledgerModal: false,
            currentSelect: null,
            modalAlertDeleteWarehouse: false,
            modalAlertDeleteLocation: false,
            listLocations: [],
            listLocationTags: [],
            selectAll: false,
            modalAlertDeleteMaster: false,
            subscription: null,
            uploadCSVTimer: null,
            dataChangeFlag: false,
            csvWorking: false,
            listLocations: [],
            listWarehouses: []
        };

    },
    computed: {
        modalHeaders() {
            var currentHeaders = this.headers
            const currentActiveCategorie = this.getCurrentActiveCategorie();
            if (currentActiveCategorie.label === "warehouse") {
                currentHeaders = this.headers.filter(item => item.key !== "map")
            }
            return [{
                key: 'delete',
                label: this.$t("select"),
            }, ...currentHeaders]
        }
    },

    mounted() {
        this.addSubscription()

        if (this.$route.params.categorie === "1") {
            this.categories[0].isActive = false
            this.categories[1].isActive = true
        }
        this.tenantId = localStorage.getItem(global.tenantId);
        this.fetchTenant()
        this.fetchMenuSettings()
        this.setDataFromCurrentActiveCategorie();

        this.reader.addEventListener("load", function (event) {
            this.showOverlay = true
            this.csvUploadMessage = this.$t("csv_upload_loading")
            this.uploadCSV(this.reader.result).then((response) => {
                if (response === undefined) {
                    this.csvUploadMessage = this.$t("csv_upload_error")
                    return
                }
                const categorie = this.getCurrentActiveCategorie();
                if (categorie.label === "item") {
                    var callApiTime = new Date().getTime()
                    this.uploadCSVTimer = setInterval(() => {
                        if (new Date().getTime() - callApiTime >= 300000) {
                            clearInterval(this.uploadCSVTimer)
                            this.csvUploadMessage = this.$t("csv_upload_timeout")
                        }
                    }, 1000)
                } else {
                    setTimeout(() => {
                        this.clearData()
                        this.setDataFromCurrentActiveCategorie()
                        this.csvUploadMessage = this.$t("csv_upload_success")
                    }, 3000)

                }

                this.clearInput()
            })
        }.bind(this), false);
    },

    watch: {
        warehouseSelect: function () {
            this.clearData()
            this.setDataFromCurrentActiveCategorie();
        },
        currentPage: function () {
            this.updatePage();
        }
    },
    unmounted() {
        console.log("unsubscribe");
        if (this.subscription !== null) {
            this.subscription.unsubscribe();
        }
    },
    methods: {
        async fetchTenant() {
            const response = await AwsApi.graphql({
                query: queries.getTenant,
                variables: {
                    tenantId: this.tenantId
                }
            }, this);
            if (response === undefined) {
                return
            }
            const customerMasters = response.data.getTenant.customer_masters
            if (customerMasters !== undefined && customerMasters !== null && customerMasters.length > 0) {
                customerMasters.forEach(async item => {
                    const response = await AwsApi.graphql({
                        query: queries.queryHeadersWithTable,
                        variables: {
                            tenantId: this.tenantId,
                            sourceTable: item
                        }
                    }, this);
                    if (response === undefined) {
                        return
                    }
                    const headers = response.data.queryItemDefines.items.map(item => {
                        const datas = item.source.split('.')
                        var itemKey = ""
                        if (datas.length == 2) {
                            itemKey = datas[1]
                        } else if (datas.length == 3) {
                            itemKey = datas[2]
                        }
                        return {
                            key: itemKey, //item.itemKey !== null ? item.itemKey : item.name,
                            label: item.name,
                            id: item.order, //item.id
                            source: item.source
                        }
                    }).sort(function (first, second) {
                        return first['id'] - second['id']
                    });

                    this.categories.push({
                        label: item,
                        name: item,
                        isActive: false,
                        header: headers,
                        url: {
                            delete: mutations.deleteCustomerMaster
                        }
                    })
                })
            }
        },
        addSubscription() {
            console.log("subscription");
            this.subscription = API.graphql(graphqlOperation(onUpdateProcessJob)).subscribe({
                next: (eventData) => {
                    clearInterval(this.uploadCSVTimer)
                    if (eventData !== undefined && eventData.value !== undefined && eventData.value.data.onUpdateProcessJob !== null &&
                        eventData.value.data.onUpdateProcessJob.status === 1) {
                        this.clearData()
                        this.setDataFromCurrentActiveCategorie()
                        this.csvUploadMessage = this.$t("csv_upload_success")
                    } else {
                        this.csvUploadMessage = this.$t("csv_upload_error")

                    }
                },
            });
        },
        async submitFormSearch() {
            this.isBusy = true
            this.clearData()
            this.fetchMasterData()
            this.form_select_options = {
                ...this.form_select_options
            }
        },
        setCategorieActive(categorieClicked) {
            const currentActiveCategorie = this.getCurrentActiveCategorie();
            if (categorieClicked.label !== currentActiveCategorie.label) {
                this.clearData()
            } else {
                return
            }
            currentActiveCategorie.isActive = false;
            categorieClicked.isActive = true
            this.getItemHeaders(categorieClicked);
            this.setCategoryAndfetchData(categorieClicked);

        },
        setDataFromCurrentActiveCategorie() {
            const currentActiveCategorie = this.getCurrentActiveCategorie();
            currentActiveCategorie.isActive = true
            this.getItemHeaders(currentActiveCategorie);
            this.setCategoryAndfetchData(currentActiveCategorie);

        },
        getCurrentActiveCategorie() {
            return this.categories.find(categorie => categorie.isActive === true);
        },
        async getItemHeaders(currentActiveCategorie) {
            if (currentActiveCategorie.label === "item" && this.categories[0].isActive) {
                const response = await AwsApi.graphql({
                    query: queries.queryHeadersWithTable,
                    variables: {
                        tenantId: this.tenantId,
                        sourceTable: "ItemMaster"
                    }
                }, this);
                if (response === undefined) {
                    return
                }
                this.headers = response.data.queryItemDefines.items.map(item => {
                    const datas = item.source.split('.')
                    var itemKey = ""
                    if (datas.length == 2) {
                        itemKey = datas[1]
                    } else if (datas.length == 3) {
                        itemKey = datas[2]
                    }
                    return {
                        key: itemKey, //item.itemKey !== null ? item.itemKey : item.name,
                        label: item.name,
                        id: item.order, //item.id
                        source: item.source
                    }
                }).sort(function (first, second) {
                    return first['id'] - second['id']
                });

                this.headers = [...currentActiveCategorie.header, ...this.headers]
            } else {
                this.headers = currentActiveCategorie.header
            }
        },
        setCategoryAndfetchData(categorie) {
            this.isBusy = true
            categorie.isActive = true;
            this.currentData = []
            switch (categorie.label) {
                case "item": {
                    this.fetchMasterData()
                    break;
                }
                case "warehouse": {
                    this.fetchWarehouseData()
                    break;
                }
                case "location": {
                    this.loadWarehousesOption().then(() => {
                        this.fetchLocationData()
                    })
                    break;
                }
                default: {
                    this.fetchCustomerMasterData(categorie.label)
                }
            }
        },
        async fetchMenuSettings() {
            const response = await AwsApi.graphql({
                query: queries.getMenu,
                variables: {
                    tenantId: this.tenantId,
                    id: parseInt(this.$route.params.id)
                }
            }, this);
            if (response === undefined) {
                return
            }
            const menuConfigs = response.data.getMenu.configs.items
            if (menuConfigs.length === 0) {
                return
            }
            this.setFormFields(menuConfigs);
            this.getOption()
        },
        setFormFields(menuConfigs) {
            this.formItems =
                menuConfigs
                .filter(item => item.searchOrder > 0)
                .sort((a, b) => a.searchOrder - b.searchOrder)
                .map(item => {

                    return ({
                        'id': item.id,
                        'title': item.name,
                        'type': this.getType(item.sourceDefine),
                        'options': item.source,
                        'sourceDefine': item.sourceDefine
                    })
                })
        },
        getType(sourceDefine) {
            // get the type
            if (sourceDefine === null || sourceDefine.inputType === null) {
                return 'input'
            }
            switch (sourceDefine.inputType) {
                case 1:
                    return 'multi_select'
                case 2:
                    return 'date_range'
                case 3:
                    return 'select'
                case 4:
                    return 'datetime_range'
                case 5:
                    return 'checkbox'
                case 6:
                    return 'checkbox_and_date'
                default:
                    return 'input'
            }
        },
        async getOption() {
            this.formItems.forEach(async item => {
                if (item.sourceDefine === null || item.sourceDefine.items === null) {
                    return null
                }
                var options = []
                if (item.sourceDefine.inputType !== 3) {
                    options = Object.entries(JSON.parse(item.sourceDefine.items)).map(([key, value]) => ({
                        text: value,
                        value: key
                    }))
                }
                const status = {
                    [item.options]: options
                };
                this.form_select_options = {
                    ...status
                }
            })

        },
        setRequestParam() {
            const myInit = {
                limit: this.limit,
                offset: this.offset,
            };
            for (const key in this.form) {
                const hasKey = this.formItems.some(i => i.options === key)
                const isDate = this.formItems.some(i => i.sourceDefine && (i.sourceDefine.inputType === 2 || i.sourceDefine.inputType === 4 || i.sourceDefine.inputType === 6) && i.options === key)
                if (hasKey) {
                    if (this.form[key] !== null && this.form[key] !== '') {
                        if (typeof (this.form[key]) === "boolean") {
                            if (this.form[key] === true) {
                                myInit[key] = this.form[key]
                            }
                        } else if (typeof (this.form[key]) == "string" && !isDate) {
                            myInit[key] = "%" + this.form[key] + "%"
                        } else {
                            myInit[key] = this.form[key]
                        }

                    }
                }
            }
            return myInit
        },
        async fetchMasterData() {
            console.log("fetchMasterData....")
            if (this.$refs.ledgerForm) {
                this.form = this.$refs.ledgerForm.beforeSubmit()
            }
            const myInit = this.setRequestParam()

            const response = await AwsApi.graphql({
                query: queries.getItemMasterList,
                variables: {
                    conditions: JSON.stringify(myInit)
                }
            }, this);
            if (response === undefined) {
                this.isBusy = false
                return
            }
            if (!this.categories[0].isActive) {
                return
            }

            const convertData = response.data.getItemMasterList.map(item => {
                return JSON.parse(item)
            })
            this.allData = this.allData.concat(convertData);
            this.setData();
            if (this.dataChangeFlag) {
                this.dataChangeFlag = false
                this.currentPage = 1
            }
            this.isBusy = false
        },
        setData() {
            if (this.categories[0].isActive) {
                this.currentData = this.allData.slice((this.currentPage - 1) * this.count, this.currentPage * this.count).map(item => {
                    var jsonProperties = {}
                    try {
                        jsonProperties = JSON.parse(item.properties)
                    } catch (error) {}
                    return {
                        ...item,
                        ...jsonProperties
                    }
                })
            } else {
                this.currentData = this.allData.slice((this.currentPage - 1) * this.count, this.currentPage * this.count)
                this.currentData = this.currentData.map(item => {
                    return {
                        ...item,
                        imageLoadFlag: false
                    }
                })
                this.currentData.forEach(async item => {
                    item.signedURL = await this.getMap(item.upload)
                    item.imageLoadFlag = true
                })

            }
            this.pagination.totalRow = this.allData.length;
            this.pagination.perPage = this.count;
            if (!this.modalCreate) {
                this.modalItemData = this.currentData.map(a => ({
                    ...a
                }));
            }
        },
        updatePage() {
            if (this.currentPage * this.count >= this.limit + this.offset) {
                this.offset += this.limit
                const currentActiveCategorie = this.getCurrentActiveCategorie();
                if (currentActiveCategorie.label !== "location") {
                    this.setCategoryAndfetchData(currentActiveCategorie);
                } else {
                    this.setData()
                }
            } else {
                this.setData()
            }
        },
        async fetchWarehouseData() {
            this.currentData = []
            const response = await AwsApi.graphql({
                query: queries.listWarehouses,
                variables: {
                    tenantId: this.tenantId
                }
            }, this);
            if (response === undefined) {
                this.isBusy = false
                return
            }
            if (!this.categories[1].isActive) {
                return
            }
            this.allData = this.allData.concat(response.data.listWarehouses.items);
            this.setData();
            if (this.dataChangeFlag) {
                this.dataChangeFlag = false
                this.currentPage = 1
            }
            this.isBusy = false
        },
        async fetchLocationData() {
            this.currentData = []
            if (!this.warehouseSelect && this.listWarehouses.length !== 0) {
                this.warehouseSelect = this.listWarehouses[0].value;
                return
            }
            this.listLocations = []
            this.listLocationTags = []
            await this.getListLocations(this.warehouseSelect, undefined)
            await this.getListLocationTags(this.warehouseSelect, undefined)

            if (!this.categories[2].isActive) {
                return
            }
            const warehouse = this.listWarehouses.find(warehouse => warehouse.value === this.warehouseSelect)
            const showLists = this.listLocations.map(item => {
                return {
                    ...item,
                    warehouseId: warehouse.text,
                    epc: this.listLocationTags.filter(itemTag => itemTag.location_id === item.id).map(item => item.epc)

                }
            })
            this.allData = this.allData.concat(showLists);
            this.allData = this.allData.sort(function (a, b) {
                return a.number - b.number
            })
            this.setData();
            if (this.dataChangeFlag) {
                this.dataChangeFlag = false
                this.currentPage = 1
            }
            this.isBusy = false
        },
        async getListLocations(warehouseId, nextTokenValue) {
            const response = await AwsApi.graphql({
                query: queries.queryLocationsByWarehouseId,
                variables: {
                    tenantId: this.tenantId,
                    warehouseId: {
                        eq: warehouseId
                    },
                    nextToken: nextTokenValue

                }
            }, this);
            if (response === undefined) {
                return
            }
            this.listLocations = this.listLocations.concat(response.data.queryLocationsByWarehouseId.items);
            if (response.data.queryLocationsByWarehouseId.nextToken !== null) {
                this.offset += this.limit
                await this.getListLocations(warehouseId, response.data.queryLocationsByWarehouseId.nextToken)
            }
        },
        async getListLocationTags(warehouseId, nextTokenValue) {
            const response = await AwsApi.graphql({
                query: queries.queryLocationTagsByWarehouseId,
                variables: {
                    tenantId: this.tenantId,
                    warehouseId: {
                        eq: warehouseId
                    },
                    nextToken: nextTokenValue

                }
            }, this);
            if (response === undefined) {
                return
            }
            this.listLocationTags = this.listLocationTags.concat(response.data.queryLocationTagsByWarehouseId.items);
            if (response.data.queryLocationTagsByWarehouseId.nextToken !== null) {
                await this.getListLocationTags(warehouseId, response.data.queryLocationTagsByWarehouseId.nextToken)
            }
        },
        openModalCreateRow(evt) {
            this.modalItemData = []
            this.modalCreate = true;
            this.ledgerModal = true
        },
        openModalUpdateRow(evt) {
            this.modalItemData = this.currentData.map(a => {
                if (a["searchFinishedFlag"] !== undefined && a["searchFinishedFlag"] !== null) {
                    if (a["searchFinishedFlag"] === 1) {
                        a["searchFinishedFlag"] = true
                    } else if (a["searchFinishedFlag"] === 0) {
                        a["searchFinishedFlag"] = false
                    }
                }
                if (!a.hasOwnProperty("id")) {
                    a.id = uuidv4()
                }
                return {
                    ...a
                }
            });
            this.modalCreate = false;
            this.ledgerModal = true
        },
        async onclickAddItem() {
            const item = await this.newItemToAdd();
            this.modalItemData.push(item);
        },
        onclickItemUpdate() {
            const currentActiveCategorie = this.getCurrentActiveCategorie();
            switch (currentActiveCategorie.label) {
                case "item": {
                    this.apiUpdateOrCreateItem()
                    break;
                }
                case "warehouse": {
                    this.apiUpdateOrCreateWarehouses()
                    break;
                }
                case "location": {
                    this.apiUpdateLocations()
                    break;
                }
                default: {
                    this.apiUpdateCustomerMaster(currentActiveCategorie.label)
                }
            }
        },
        async apiUpdateOrCreateItem() {
            AwsApi.checkSession(() => {
                const create = [];
                const update = [];
                const _this = this
                if (this.modalCreate) {
                    this.modalItemData.forEach(item => {
                        if (item.itemCode !== undefined && item.itemCode !== null && item.itemCode !== '') {
                            const json = _this.createSendJson(item)
                            create.push(API.graphql({
                                query: mutations.createItemMaster,
                                variables: {
                                    createItemMaster: JSON.stringify(json)
                                }
                            }));
                        }
                    })
                } else {
                    const itemsToUpdate = this.modalItemData.filter(item => item.toUpdate);
                    itemsToUpdate.forEach(item => {
                        const json = _this.createSendJson(item)
                        json['id'] = item.id
                        update.push(API.graphql({
                            query: mutations.updateItemMaster,
                            variables: {
                                updateItemMaster: JSON.stringify(json)
                            }
                        }));
                    })
                }
                Promise.all([...create, ...update]).then((values) => {
                    values.forEach(item => {
                        if (item.data.createItemMaster === -1) {
                            alert("データ挿入失敗しました。該当データがすでに存在している可能性があります。")
                        }
                        if (item.data.updateItemMaster === -1) {
                            alert("データの更新に失敗しました。")
                        }
                    })
                    this.isBusy = true
                    this.clearData()
                    this.fetchMasterData()
                    this.ledgerModal = false
                    this.removeNewItems()
                })
            }, this)
        },
        createSendJson(item) {
            const json = {}
            console.log("-------")
            this.headers.forEach((h) => {
                const sources = h.source.split(".")
                if (sources.length == 2) {
                    if (item[sources[1]] !== undefined) {
                        json[sources[1]] = item[sources[1]]
                    }
                    const pre = {}
                    pre[h.label] = item[sources[1]]
                    json['properties'] = {
                        ...json['properties'],
                        ...pre
                    }
                } else if (sources.length == 3) {
                    if (item[sources[2]] !== undefined) {
                        const pre = {}
                        pre[sources[2]] = item[sources[2]]
                        json['properties'] = {
                            ...json['properties'],
                            ...pre
                        }
                    }

                }
            })
            if (json['properties'] !== undefined) {
                json['properties'] = JSON.stringify(json['properties'])
            }
            return json
        },
        async apiUpdateOrCreateWarehouses() {
            AwsApi.checkSession(async () => {
                const create = [];
                const update = [];
                if (this.modalCreate) {
                    this.modalItemData.forEach(item => {
                        if (item.rssi_threshhold === "") {
                            item.rssi_threshhold = null
                        }
                        if (item.state_change_interval === "") {
                            item.state_change_interval = null
                        }
                        if (item.all_shelves_scan_percentage === "") {
                            item.all_shelves_scan_percentage = null
                        }
                        if (item.robot_home_x === "") {
                            item.robot_home_x = null
                        }
                        if (item.robot_home_y === "") {
                            item.robot_home_y = null
                        }
                        if (item.robot_stop_meters === "") {
                            item.robot_stop_meters = null
                        }
                        if (item.robot_stop_seconds === "") {
                            item.robot_stop_seconds = null
                        }
                        if (item.robot_distance === "") {
                            item.robot_distance = null
                        }
                        if (item.robot_orientation === "") {
                            item.robot_orientation = null
                        }

                        if (item.shelf_transparency === "") {
                            item.shelf_transparency = null
                        }

                        if (item.item_tag_filter === undefined || item.item_tag_filter === null) {
                            item.item_tag_filter = ""
                        }

                        if (item.location_tag_filter === undefined || item.location_tag_filter === null) {
                            item.location_tag_filter = ""
                        }

                        var createItem = {
                            group: this.tenantId,
                            tenantId: this.tenantId,
                            id: item.id,
                            name: item.name,
                            rssi_threshhold: item.rssi_threshhold,
                            real_width: item.real_width,
                            real_height: item.real_height,
                            item_tag_filter: item.item_tag_filter,
                            location_tag_filter: item.location_tag_filter,
                            state_change_interval: item.state_change_interval,
                            all_shelves_scan_percentage: item.all_shelves_scan_percentage,
                            remarks: item.remarks,
                            robot_home_x: item.robot_home_x,
                            robot_home_y: item.robot_home_y,
                            robot_stop_meters: item.robot_stop_meters,
                            robot_stop_seconds: item.robot_stop_seconds,
                            robot_distance: item.robot_distance,
                            robot_orientation: item.robot_orientation,
                            shelf_transparency: item.shelf_transparency

                        }
                        create.push(API.graphql({
                            query: mutations.createWarehouse,
                            variables: {
                                input: createItem
                            }
                        }));
                    })
                } else {
                    const itemsToUpdate = this.modalItemData.filter(item => item.toUpdate);
                    itemsToUpdate.forEach(item => {
                        if (item.rssi_threshhold === "") {
                            item.rssi_threshhold = null
                        }
                        if (item.state_change_interval === "") {
                            item.state_change_interval = null
                        }
                        if (item.all_shelves_scan_percentage === "") {
                            item.all_shelves_scan_percentage = null
                        }
                        if (item.robot_home_x === "") {
                            item.robot_home_x = null
                        }
                        if (item.robot_home_y === "") {
                            item.robot_home_y = null
                        }
                        if (item.robot_stop_meters === "") {
                            item.robot_stop_meters = null
                        }
                        if (item.robot_stop_seconds === "") {
                            item.robot_stop_seconds = null
                        }
                        if (item.robot_distance === "") {
                            item.robot_distance = null
                        }
                        if (item.robot_orientation === "") {
                            item.robot_orientation = null
                        }
                        if (item.shelf_transparency === "") {
                            item.shelf_transparency = null
                        }

                        if (item.item_tag_filter === undefined || item.item_tag_filter === null) {
                            item.item_tag_filter = ""
                        }

                        if (item.location_tag_filter === undefined || item.location_tag_filter === null) {
                            item.location_tag_filter = ""
                        }

                        var updateItem = {
                            tenantId: this.tenantId,
                            id: item.id,
                            name: item.name,
                            item_tag_filter: item.item_tag_filter,
                            location_tag_filter: item.location_tag_filter,
                            all_shelves_scan_percentage: item.all_shelves_scan_percentage,
                            rssi_threshhold: item.rssi_threshhold,
                            state_change_interval: item.state_change_interval,
                            robot_home_x: item.robot_home_x,
                            robot_home_y: item.robot_home_y,
                            robot_stop_meters: item.robot_stop_meters,
                            robot_stop_seconds: item.robot_stop_seconds,
                            robot_distance: item.robot_distance,
                            robot_orientation: item.robot_orientation,
                            shelf_transparency: item.shelf_transparency
                        }
                        update.push(API.graphql({
                            query: mutations.updateWarehouse,
                            variables: {
                                input: updateItem
                            }
                        }));
                    })
                }
                Promise.all([...create, ...update]).then(() => {
                    this.clearData()
                    this.isBusy = true
                    this.fetchWarehouseData()
                    this.ledgerModal = false
                    this.removeNewItems()
                    this.$bus.emit('fetchWarehouse');
                })

            }, this)
        },
        apiUpdateLocations() {
            AwsApi.checkSession(() => {
                const update = [];

                const itemsToUpdate = this.modalItemData.filter(item => item.toUpdate);
                itemsToUpdate.forEach(item => {
                    if (item.number === "") {
                        item.number = null
                    }
                    var updateItem = {
                        tenantId: this.tenantId,
                        number: item.number,
                        id: item.id,
                        label: item.label
                    }

                    update.push(API.graphql({
                        query: mutations.updateLocation,
                        variables: {
                            input: updateItem
                        }
                    }));
                })

                Promise.all([...update]).then((e) => {
                    this.clearData()
                    this.isBusy = true
                    this.fetchLocationData()
                    this.ledgerModal = false
                    this.removeNewItems()
                })
            }, this)
        },
        apiUpdateCustomerMaster(tableName) {
            AwsApi.checkSession(() => {
                const update = [];

                const items = this.modalItemData.filter(item => item.toCreate || item.toUpdate);
                items.forEach(item => {
                    delete item["id"]
                    delete item["toCreate"]
                    delete item["toUpdate"]
                    delete item["signedURL"]
                    delete item["imageLoadFlag"]
                    update.push(API.graphql({
                        query: mutations.updateCustomerMaster,
                        variables: {
                            tableName: tableName,
                            master: JSON.stringify(item)
                        }
                    }));
                })

                Promise.all([...update]).then((e) => {
                    this.clearData()
                    this.isBusy = true
                    this.fetchCustomerMasterData(tableName)
                    this.ledgerModal = false
                    this.removeNewItems()
                })
            }, this)
        },
        onclickItemDelete() {
            const currentActiveCategorie = this.getCurrentActiveCategorie();
            if (currentActiveCategorie.label === "warehouse") {
                this.modalAlertDeleteWarehouse = true
            } else if (currentActiveCategorie.label === "location") {
                this.modalAlertDeleteLocation = true
            } else {
                switch (currentActiveCategorie.label) {
                    case "item": {
                        this.deleteDataFun()
                        break;
                    }
                    default: {
                        this.deleteCustomerMasterData(currentActiveCategorie.label)
                    }
                }

            }
        },
        deleteDataFun() {
            if (this.modalCreate) {
                this.selection.forEach((item, index) => {
                    this.modalItemData = this.modalItemData.filter(data => data.id !== item.id)
                })
                return
            }
            const currentActiveCategorie = this.getCurrentActiveCategorie();
            this.apiDeleteSelection(currentActiveCategorie)
        },
        async apiDeleteSelection(currentActiveCategorie) {
            AwsApi.checkSession(() => {
                const deletePro = []
                this.selection.forEach((item, index) => {
                    const deleteItem = {
                        tenantId: this.tenantId,
                        id: item.id
                    }
                    var variables = {
                        id: item.id
                    }
                    if (currentActiveCategorie.label !== "item") {
                        variables = {
                            input: deleteItem
                        }
                    }
                    deletePro.push(API.graphql({
                        query: currentActiveCategorie.url.delete,
                        variables: variables
                    }));
                    this.allData = this.allData.filter(data => data.id !== item.id)
                    this.currentData = this.currentData.filter(data => data.id !== item.id)
                    if (currentActiveCategorie.label === "warehouse") {
                        this.deleteAllLocationTag(item.id)
                    }
                })

                Promise.all([...deletePro]).then(() => {
                    this.selection = []
                    this.updatePage()
                    if (currentActiveCategorie.label === "warehouse") {
                        this.$bus.emit('fetchWarehouse');
                    } else if (currentActiveCategorie.label === "item") {
                        this.isBusy = true
                        this.clearData()
                        this.fetchMasterData()
                        this.ledgerModal = false
                        this.removeNewItems()
                    }
                })
            }, this)
        },
        async deleteCustomerMasterData(tableName) {
            if (this.modalCreate) {
                this.selection.forEach((item, index) => {
                    this.modalItemData = this.modalItemData.filter(data => data.id !== item.id)
                })
                return
            }
            AwsApi.checkSession(() => {
                const deletePro = []
                this.selection.forEach((item, index) => {
                    delete item["id"]
                    delete item["toCreate"]
                    delete item["toUpdate"]
                    delete item["signedURL"]
                    delete item["imageLoadFlag"]
                    deletePro.push(API.graphql({
                        query: mutations.deleteCustomerMaster,
                        variables: {
                            tableName: tableName,
                            master: JSON.stringify(item)
                        }
                    }));
                })

                Promise.all([...deletePro]).then(() => {
                    this.selection = []
                    this.isBusy = true
                    this.clearData()
                    this.fetchCustomerMasterData(tableName)
                    this.ledgerModal = false
                    this.removeNewItems()
                })
            }, this)
        },
        async deleteAllLocationTag(warehouseId) {
            this.listLocations = [],
                this.listLocationTags = [],
                await this.getListLocations(warehouseId, undefined)
            await this.getListLocationTags(warehouseId, undefined)
            this.listLocations.forEach((item) => {
                API.graphql({
                    query: mutations.deleteLocation,
                    variables: {
                        input: {
                            tenantId: this.tenantId,
                            id: item.id
                        }
                    }
                });
            })
            this.listLocationTags.forEach((item) => {
                API.graphql({
                    query: mutations.deleteLocationTag,
                    variables: {
                        input: {
                            tenantId: this.tenantId,
                            id: item.id
                        }
                    }
                });
            })
        },
        updateItem(value, item, key) {
            if (typeof value === 'string') {
                if (value.includes("\t")) {
                    value = value.replace('\t', '')
                }
                if (value.includes("\\t")) {
                    value = value.replace('\\\\t', '').replace('\\t', '')
                }
            }
            if (this.modalCreate) {
                const items = [...this.modalItemData];
                const itemToCreate = items.find(i => i.id === item.id);
                itemToCreate[key] = value;
            } else {
                const items = [...this.modalItemData];
                const itemToUpdate = items.find(i => i.id === item.id);
                itemToUpdate[key] = value;
                itemToUpdate.toUpdate = true
            }
        },
        async newItemToAdd() {
            const categorie = this.getCurrentActiveCategorie().label
            let item = null
            switch (categorie) {
                case 'item':
                    item = {
                        id: uuidv4(),
                        toCreate: true,
                    };
                    break;
                case 'warehouse':
                    if (this.tmpId === 0) {
                        const response = await API.graphql({
                            query: queries.listWarehouses,
                            variables: {
                                tenantId: this.tenantId
                            }
                        });
                        const list = response.data.listWarehouses.items
                        if (list.length >= 2) {
                            this.tmpId = list.reduce((a, b) => {
                                return a.id > b.id ? a.id : b.id
                            })
                        } else if (list.length === 1) {
                            this.tmpId = list[0].id
                        }
                    }

                    this.tmpId = this.tmpId + 1
                    item = {
                        name: `${this.$t("warehouse")}`,
                        id: this.tmpId,
                        toCreate: true,
                    };
                    break;
                default:
                    item = {
                        id: uuidv4(),
                        toCreate: true,
                    };
            }

            return item;
        },
        removeNewItems() {
            this.modalItemData = []
            this.selection = []
            this.currentSelect = null
            this.selectAll = false
        },
        async loadWarehousesOption() {
            const response = await AwsApi.graphql({
                query: queries.listWarehouses,
                variables: {
                    tenantId: this.tenantId
                }
            }, this);
            if (response === undefined) {
                return
            }
            if (!this.categories[2].isActive) {
                return
            }

            this.listWarehouses = response.data.listWarehouses.items.map((item) => ({
                value: item.id,
                text: `${item.name}`,
            }));
        },
        clearData() {
            this.dataChangeFlag = true
            this.allData = []
            this.currentData = []
            this.offset = 0
            this.pagination = {
                totalRow: 0,
                perPage: 0
            }
        },
        async uploadCSV(csvStr) {
            const categorie = this.getCurrentActiveCategorie();
            var isItemMasterUpload = true
            if (categorie.label === "item") {
                isItemMasterUpload = true
            } else {
                isItemMasterUpload = false
            }

            if (/\.(xlsx)$/i.test(this.$refs.file.files[0].name)) {
                if (isItemMasterUpload) {
                    this.updateCsvPath = `${this.tenantId}/master/${this.$route.query.warehouse}_${global.formatLongDate()}.xlsx`
                } else {
                    this.updateCsvPath = `${this.tenantId}/customer/${categorie.label}_${global.formatLongDate()}.xlsx`
                }
                return await Storage.put(this.updateCsvPath, this.$refs.file.files[0]);
            } else if (/\.(xlsm)$/i.test(this.$refs.file.files[0].name)) {
                if (isItemMasterUpload) {
                    this.updateCsvPath = `${this.tenantId}/master/${this.$route.query.warehouse}_${global.formatLongDate()}.xlsm`
                } else {
                    this.updateCsvPath = `${this.tenantId}/customer/${categorie.label}_${global.formatLongDate()}.xlsm`
                }
                return await Storage.put(this.updateCsvPath, this.$refs.file.files[0]);
            } else {
                if (isItemMasterUpload) {
                    this.updateCsvPath = `${this.tenantId}/master/${this.$route.query.warehouse}_${global.formatLongDate()}.csv`
                } else {
                    this.updateCsvPath = `${this.tenantId}/customer/${categorie.label}_${global.formatLongDate()}.csv`
                }
                return await Storage.put(this.updateCsvPath, csvStr, {
                    contentType: "text/csv",
                });
            }
        },
        clearInput() {
            const input = this.$refs.file
            input.type = 'text'
            input.type = 'file'
        },
        handleFileUpload() {
            this.file = this.$refs.file.files[0];

            /*
              Check to see if the file is not empty.
            */
            if (this.file) {
                if (/\.(csv)$/i.test(this.file.name)) {
                    this.reader.readAsText(this.file);
                }
                if (/\.(xlsx|xlsm)$/i.test(this.file.name)) {
                    this.reader.readAsArrayBuffer(this.file);
                }
            }
        },
        downloadCSV() {
            this.isCsvBusy = true
            this.getCsvALlListData().then(() => {
                var csv = ""
                this.headers.forEach((item) => {
                    if (item.label === undefined || item.label === null) {
                        item.label = ""
                    }
                    if (csv === '') {
                        csv = csv + item.label
                    } else {
                        csv = csv + ',' + item.label
                    }
                })
                csv = csv + '\n'
                this.allData.forEach((item) => {
                    var jsonProperties = {}
                    try {
                        jsonProperties = JSON.parse(item.properties)
                    } catch (error) {}
                    const elements = {
                        ...item,
                        ...jsonProperties
                    }

                    this.headers.forEach((key, index) => {
                        var value = elements[key.key]

                        if (value === undefined || value === null) {
                            value = ""
                        }
                        if (index === 0) {
                            csv = csv + value
                        } else {
                            csv = csv + ',' + value
                        }
                    })
                    csv = csv + '\n'

                })

                let bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
                let blob = new Blob([bom, csv], {
                    type: 'text/csv'
                })
                const downloadLink = document.createElement("a");
                downloadLink.href = window.URL.createObjectURL(blob);
                downloadLink.download = `master.csv`;
                downloadLink.click();
                this.isCsvBusy = false
            })

        },
        async getCsvALlListData() {
            this.offset += this.limit
            if (this.$refs.ledgerForm) {
                this.form = this.$refs.ledgerForm.beforeSubmit()

            }
            const myInit = this.setRequestParam()

            const response = await AwsApi.graphql({
                query: queries.getItemMasterList,
                variables: {
                    conditions: JSON.stringify(myInit)
                }
            }, this)
            if (response === undefined) {
                return
            }
            if (response.data.getItemMasterList.length !== 0) {
                const convertData = response.data.getItemMasterList.map(item => {
                    return JSON.parse(item)
                })
                this.allData = this.allData.concat(convertData);
                this.pagination.totalRow = this.allData.length;
                this.pagination.perPage = this.count;
                if (response.data.getItemMasterList.length === 1000) {
                    await this.getCsvALlListData()
                }
            }
        },
        rowSelected(rowSelected) {
            this.currentSelect = rowSelected
        },
        rowUnselected(rowUnselected) {
            this.currentSelect = null
        },
        async getMap(src) {
            var signedURL = ""
            if (src !== undefined && src !== null) {
                if (src.includes("private/")) {
                    signedURL = await Storage.get(src.slice(8), {
                        level: 'private'
                    });
                } else if (src.includes("protected/")) {
                    signedURL = await Storage.get(src.slice(10), {
                        level: 'protected'
                    });
                } else if (src.includes("public/")) {
                    signedURL = await Storage.get(src.slice(7));
                }
            }
            return signedURL
        },
        onclickSelectAll() {
            if (this.selectAll) {
                this.selection = this.modalItemData
            } else {
                this.selection = []
            }
        },
        onClickDeleteAll() {
            this.modalAlertDeleteMaster = true
        },
        deleteMasterDataFun() {
            API.graphql({
                query: mutations.deleteItemMaster,
                variables: {
                    id: -1
                }
            }).then(_ => {
                this.clearData()
                this.fetchMasterData()
            })
        },
        downloadWarehouseCsv() {
            if (this.selection.length === 0) {
                return
            }
            this.csvWorking = true
            AwsApi.checkSession(() => {
                this.selection.forEach(async (item, index) => {
                    const response = await API.graphql({
                        query: queries.getWarehouse,
                        variables: {
                            tenantId: this.tenantId,
                            id: item.id
                        }
                    });
                    if (response === undefined) {
                        return
                    }
                    if (response.data.getWarehouse !== null) {
                        this.listLocations = []
                        this.listLocationTags = []
                        await this.getListLocations(item.id, undefined)
                        await this.getListLocationTags(item.id, undefined)
                        this.csvWorking = true
                        this.createCSVWarehouseLink(response.data.getWarehouse)
                        this.createCSVLocationsLink()
                        this.createCSVLocationTagsLink()
                        this.createSVGLink(response.data.getWarehouse.upload)
                        this.csvWorking = false
                    }

                })

            }, this)
        },
        createCSVWarehouseLink(warehouse) {
            var csv = "tenantId,id,name,width,height,real_width,real_height,default_shelf_height,default_shelf_width,rssi_threshhold,state_change_interval,all_shelves_scan_percentage,remarks,location_tag_filter,item_tag_filter,upload,svg,robot_home_x,robot_home_y,robot_stop_meters,robot_stop_seconds,robot_distance,robot_orientation,shelf_transparency\n"
            csv = csv + this.checkDataNull(warehouse.tenantId) + ","
            csv = csv + this.checkDataNull(warehouse.id) + ","
            csv = csv + this.checkDataNull(warehouse.name) + ","
            csv = csv + this.checkDataNull(warehouse.width) + ","
            csv = csv + this.checkDataNull(warehouse.height) + ","
            csv = csv + this.checkDataNull(warehouse.real_width) + ","
            csv = csv + this.checkDataNull(warehouse.real_height) + ","
            csv = csv + this.checkDataNull(warehouse.default_shelf_height) + ","
            csv = csv + this.checkDataNull(warehouse.default_shelf_width) + ","
            csv = csv + this.checkDataNull(warehouse.rssi_threshhold) + ","
            csv = csv + this.checkDataNull(warehouse.state_change_interval) + ","
            csv = csv + this.checkDataNull(warehouse.all_shelves_scan_percentage) + ","
            csv = csv + this.checkDataNull(warehouse.remarks) + ","
            csv = csv + this.checkDataNull(warehouse.location_tag_filter) + ","
            csv = csv + this.checkDataNull(warehouse.item_tag_filter) + ","
            csv = csv + this.checkDataNull(warehouse.upload) + ","
            csv = csv + this.checkDataNull(warehouse.svg) + ","
            csv = csv + this.checkDataNull(warehouse.robot_home_x) + ","
            csv = csv + this.checkDataNull(warehouse.robot_home_y) + ","
            csv = csv + this.checkDataNull(warehouse.robot_stop_meters) + ","
            csv = csv + this.checkDataNull(warehouse.robot_stop_seconds) + ","
            csv = csv + this.checkDataNull(warehouse.robot_distance) + ","
            csv = csv + this.checkDataNull(warehouse.robot_orientation) + ","
            csv = csv + this.checkDataNull(warehouse.shelf_transparency)

            csv = csv + '\n'
            let bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
            let blob = new Blob([bom, csv], {
                type: 'text/csv'
            })
            const downloadLink = document.createElement("a");
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.download = `Warehouse_` + new Date().getTime() + `.csv`;
            downloadLink.click();

        },
        createCSVLocationsLink() {
            var csv = "id,warehouseId,x,y,width,height,rotation,label,number\n"
            this.listLocations.forEach((item, index) => {
                csv = csv + this.checkDataNull(item.id) + ",",
                    csv = csv + this.checkDataNull(item.warehouseId) + ",",
                    csv = csv + this.checkDataNull(item.x) + ",",
                    csv = csv + this.checkDataNull(item.y) + ",",
                    csv = csv + this.checkDataNull(item.width) + ",",
                    csv = csv + this.checkDataNull(item.height) + ",",
                    csv = csv + this.checkDataNull(item.rotation) + ",",
                    csv = csv + this.checkDataNull(item.label) + ",",
                    csv = csv + this.checkDataNull(item.number)
                csv = csv + '\n'
            })
            let bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
            let blob = new Blob([bom, csv], {
                type: 'text/csv'
            })
            const downloadLink = document.createElement("a");
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.download = `Location_` + new Date().getTime() + `.csv`;
            downloadLink.click();
        },
        createCSVLocationTagsLink() {
            var csv = "id,warehouseId,location_id,epc,x,y\n"
            this.listLocationTags.forEach((item, index) => {
                csv = csv + this.checkDataNull(item.id) + ",",
                    csv = csv + this.checkDataNull(item.warehouseId) + ",",
                    csv = csv + this.checkDataNull(item.location_id) + ",",
                    csv = csv + this.checkDataNull(item.epc) + ",",
                    csv = csv + this.checkDataNull(item.x) + ",",
                    csv = csv + this.checkDataNull(item.y)
                csv = csv + '\n'
            })
            let bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
            let blob = new Blob([bom, csv], {
                type: 'text/csv'
            })
            const downloadLink = document.createElement("a");
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.download = `LocationTag_` + new Date().getTime() + `.csv`;
            downloadLink.click();
        },
        async createSVGLink(upload) {
            if (upload !== undefined && upload !== null) {
                var signedURL = await Storage.get(upload.slice(7), {
                    download: true
                });
                const blob = signedURL.Body;
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = `map_` + new Date().getTime() + `.svg`;
                document.body.appendChild(link);
                link.click();
            }
        },
        checkDataNull(data) {
            if (data === undefined) {
                return null
            } else {
                return data
            }
        },
        async handleMapFileUpload(event, warehouseId, index) {
            const files = Array.from(event.target.files);
            if (files.length > 0) {
                this.showOverlay = true
                this.csvUploadMessage = this.$t("csv_upload_loading")

                try {
                    const uploadPromises = files.map(file => {
                        const updatePath = `${this.tenantId}/imports/${warehouseId}/${file.name}`
                        return Storage.put(updatePath, file, {
                            contentType: file.type
                        })
                    });
                    const results = await Promise.all(uploadPromises);
                } catch (error) {
                    console.error('Error uploading files:', error);
                    this.csvUploadMessage = this.$t("csv_upload_error")
                    return
                }
                this.csvUploadMessage = this.$t("csv_upload_success")

                event.target.type = 'text'
                event.target.type = 'file'
            }
        },
        async fetchCustomerMasterData(table) {
            this.currentData = []
            const response = await AwsApi.graphql({
                query: queries.getCustomerMaster,
                variables: {
                    tableName: table
                }
            }, this);
            if (response === undefined) {
                this.isBusy = false
                return
            }

            const masters = response.data.getCustomerMaster.map(item => JSON.parse(item))
            this.allData = this.allData.concat(masters);
            this.setData();
            if (this.dataChangeFlag) {
                this.dataChangeFlag = false
                this.currentPage = 1
            }
            this.isBusy = false
        }
    }
};
</script>
<style>
    .ledger_main_table_th {
        white-space: nowrap;
    }
    #ledgerModal___BV_modal_body_ .itemCodeCell {
        display: flex;
    }

.upload_csv_label {
    cursor: pointer;
    height: 100%;
    width: 100%;
    margin: 4px 0;
    margin-bottom: 0.22rem;
    margin-top: 0.22rem;
}

.create_label {
    cursor: pointer;
    height: 100%;
    width: 100%;
}
</style>
