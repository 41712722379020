<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div v-if="warehouse" style="margin-bottom:10px">
        <div @mousemove="mouseevent(handleDragOldShelf, $event, null)" @mouseup="handleStopDragOldShelf($event, null)" @keyup.stop="handleNudge" id="map-container">

            <div class="d-flex justify-content-between" v-if="capabilities.save">
                <h2>{{ warehouse.name }}</h2>
                <div>
                    <b-button :disabled="working" :variant="working ? `` : `outline-primary ml-auto`" @click="$router.push(`/ledger`)">キャンセル</b-button>
                    <b-button :disabled="working || warehouse._checkpoints.length === 0 " :variant="working || warehouse._checkpoints.length === 0  ? `` : `outline-primary ml-auto`" @click="serialize">保存</b-button>
                </div>
            </div>

            <b-button-toolbar class="map-btn-toolbar" aria-label="Toolbar with button groups" v-if="capabilities.buttons || capabilities.zoom_buttons">
                <b-button-group class="mx-1" v-if="capabilities.buttons" >
                    <b-button variant="light" :disabled="warehouse._checkpoints.length === 0" @click="undo"><i class="mdi mdi-undo"></i></b-button>
                </b-button-group>
                <b-button-group class="mx-1" v-if="capabilities.buttons">
                    <drag variant="light"
                          @dragstart="handleDragNewShelf($event, `shelf`)"
                          @dragend="handleStopDragNewShelf"
                          :effect-allowed="['none']"
                          drop-effect="none"
                    >
                        <b-button variant="light"><i class="mdi mdi-select"></i></b-button>
                        <template v-slot:image><div style="width:1px; height:1px;"></div></template>
                    </drag>
                    <b-button
                            :variant="drag_will_trash ? `danger`:`light`"
                            @mouseup="handleOldShelfDelete"
                            @mouseover="handleOldShelfDeleteIndicator"
                            @mouseout="drag_will_trash = false"
                    ><i :class="`mdi mdi-delete`"></i></b-button>
                </b-button-group>
                <b-button-group class="mx-1" v-if="capabilities.buttons">
                    <drag variant="light"
                          @dragstart="handleDragNewShelf($event, `item`)"
                          @dragend="handleStopDragNewShelf"
                          :effect-allowed="['none']"
                          drop-effect="none"
                    >
                        <b-button variant="light" @click="toggleItemMode"><i :class="`mdi mdi-tag`+(item_mode?` text-danger`:``)"></i></b-button>
                        <template v-slot:image><div style="width:1px; height:1px;"></div></template>
                    </drag>
                </b-button-group>
                <b-button-group class="mx-1" v-if="capabilities.zoom_buttons">
                    <b-button variant="light" @mousedown="handleZoom(`in`,true)" @mouseup="handleZoom(`in`,false)"><i class="mdi mdi-magnify-plus"></i></b-button>
                    <b-button variant="light" @mousedown="handleZoom(`out`,true)" @mouseup="handleZoom(`out`,false)"><i class="mdi mdi-magnify-minus"></i></b-button>
                </b-button-group>
                <b-button-group class="mx-1" v-if="capabilities.buttons">
                    <button class="btn btn-link" disabled>Default (m)</button>
                    <b-form-input v-model="warehouse._default_shelf_width" @input="changeDefaultSizes" style="width: 60px"></b-form-input>
                    <button class="btn btn-link" disabled>x</button>
                    <b-form-input v-model="warehouse._default_shelf_height" @input="changeDefaultSizes" style="width: 60px"></b-form-input>
                </b-button-group>
                <b-button-group class="mx-1" v-if="capabilities.buttons">
                    <b-form-checkbox
                            id="checkbox-1"
                            v-model="capabilities.snap"
                            class="snap-check"
                            name="checkbox-1"
                            :value="true"
                            :unchecked-value="false"
                    >
                        Snap
                    </b-form-checkbox>
                </b-button-group>
                <b-button-group class="mx-1" v-if="capabilities.counts">
                    <button class="btn btn-link" disabled>{{ count_location }}棚</button>
                    <button class="btn btn-link" disabled>{{ count_tag }}タグ</button>
                </b-button-group>
                <b-button-group class="mx-1" v-if="capabilities.buttons && item_selected">
                    <button class="btn btn-link" disabled>タグ番号</button>
                    <b-form-input v-model="item_selected_label.label" @keyup.enter="changeLabel" @change="changeLabel" style="width: 60px"></b-form-input>
                </b-button-group>
            </b-button-toolbar>
            <div v-if="drag_trash" :style="`z-index: 1; position: absolute; top:`+drag_oob_y+`px; left:`+drag_oob_x+`px;`" style="pointer-events: none"><i class="mdi mdi-select"></i></div>
            <div v-if="shelf_selecting !== null" :style="`z-index: 1; position: absolute; top:`+shelf_selecting.y+`px; left:`+shelf_selecting.x+`px;`" style="pointer-events: none">
                <div class="spinner-border text-primary m-2" style="width: 40px; height: 40px;" role="status"></div>
            </div>
            <div v-if="map_selecting !== null" :style="`z-index: 1; position: absolute; top:`+map_selecting.y+`px; left:`+map_selecting.x+`px;`" style="pointer-events: none">
                <div class="spinner-border text-success m-2" style="width: 40px; height: 40px;" role="status"></div>
            </div>
            <template
                    v-if="shelf_selected"
            >
                <div
                        :style="`z-index: 3; pointer-events: all; font-size: 20px; pointer-events: all; user-select: none; position: absolute; top:`+(shelf_selected_controls.y)+`px; left:`+(shelf_selected_controls.x+shelf_selected_controls.w+20)+`px;`"
                >
                    <label style="position: absolute; top: -14px; left: 25px; font-size: 16px; user-select: none">w</label>
                    <label style="position: absolute; top: -14px; left: 72px; font-size: 16px; user-select: none">h</label>
                    (<input class="shelf-input" type="text" pattern="[0-9.]*" v-model="shelf_selected._user_w" v-on:input="updateShelfFromManualInput" style="width: 40px; background-color: unset; border: none; text-align: center; user-select: text;">,<input type="text" class="shelf-input" pattern="\d*" v-model="shelf_selected._user_h" v-on:input="updateShelfFromManualInput" style="width: 40px; background-color: unset; border: none; text-align: center; user-select: text;">)</div>
                <div
                        :style="`z-index: 3; pointer-events: all; font-size: 20px; pointer-events: all; white-space: nowrap; user-select: none; position: absolute; top:`+(shelf_selected_controls.y+shelf_selected_controls.h+20)+`px; left:`+(shelf_selected_controls.x)+`px;`"
                >
                    <label style="position: absolute; top: -14px; left: 25px; font-size: 16px; user-select: none">x</label>
                    <label style="position: absolute; top: -14px; left: 72px; font-size: 16px; user-select: none">y</label>
                    (<input class="shelf-input" type="text" pattern="[0-9]*" v-model="shelf_selected._user_x" v-on:input="updateShelfFromManualInput" style="width: 40px; background-color: unset; border: none; text-align: center; user-select: text;">,<input class="shelf-input" type="text" pattern="[0-9]*" v-model="shelf_selected._user_y" v-on:input="updateShelfFromManualInput" style="width: 40px; background-color: unset; border: none; text-align: center; user-select: text;">)</div>

                <div
                        :style="`z-index: 3; pointer-events: all; cursor: se-resize; font-size: 1.5em; pointer-events: all; position: absolute; top:`+(shelf_selected_controls.y+shelf_selected_controls.h-17)+`px; left:`+(shelf_selected_controls.x+shelf_selected_controls.w-11)+`px;`"
                        @mousedown="drag_mode = `shelf_edge`"
                        @mouseup="drag_mode = null"
                >
                    <i class="mdi mdi-drag" style="font-weight: bold"></i>
                </div>
            </template>
                   
                <div class="form-row form_container" v-if="titleData !== undefined">
                    <div class="form-group col-auto">
                        <div class="map_store">{{titleData.warehouse}}</div>
                    </div>
                    <div class="form-group col-auto map-border">
                        <div class="map_sub_count"><div class="map_sub_count_left">SKU</div><div class="map_sub_count_right">{{titleData.itemCodeAmount}}</div></div>
                    </div>
                    <div class="form-group col-auto map-border">
                        <div class="map_sub_count"><div class="map_sub_count_left">商品数</div><div class="map_sub_count_right">{{titleData.epcAmount}}</div></div>
                    </div>
                </div>
            <panZoom selector="#scene" id="panzoom" @init="initPanzoom" @zoom="updateLines" @pan="updateVisible" @transform="regenerateShelfSelectedControls" :options="{minZoom: 1, maxZoom: 2000, bounds:true,boundsPadding:0, filterKey: _=> {return true}, onTouch: _=> {return false}}">
                <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        ref="svg"
                        :width="width"
                        :height="height"
                        id="svg-map"
                        style="z-index: 2"
                        @keyup.stop="handleNudge"
                        @dragover="handleDragover"
                        @dragout="drag_x = drag_y = 0"
                        @mousemove="mouseevent(handleDragOldShelf, $event, null)" @mouseup="handleStopDragOldShelf($event, null)"
                >
                    <g id='scene'>
                        <g id="svg-fix" :transform="`scale(`+warehouse.real_width/warehouse.width+`,`+warehouse.real_height/warehouse.height+`)`">
                            <image
                                    @mousemove="mouseevent(handleDragOldShelf, $event, null)"
                                    @mouseup="handleStopDragOldShelf($event, null)"
                                    @keyup.stop="handleNudge"
                                    @click="handleDeselectAll"
                                    @mousedown="handleMapSelect"
                                    :xlink:href="signedURL" :width="warehouse.width" :height="warehouse.height" />
                        </g>

                        <g id='elements' :transform="`translate(`+warehouse.real_width * (warehouse.border_left_x+warehouse._user_border_left_x)+`,`+warehouse.real_height * (warehouse.border_left_y+warehouse._user_border_left_y)+`)`">
                            <template v-for="(location, index) in warehouse.locations">
                            <g
                                :id="'id' + location._element_id"
                                    v-if="(location._status !== undefined && location._status !== `delete` && location._status !== `ignore`) && (serializing || (
                                visible_area.top < (location.y + location._batch_y + location.height/2) && visible_area.left < (location.x + location._batch_x + location.width/2)
                                && visible_area.bottom > (location.y + location._batch_y + location.height/2) && visible_area.right > (location.x + location._batch_x + location.width/2)
                                ))
                                "
                                @mouseover="mouseover(location)"
                                    v-bind:key="location._element_id"
                            >
                                <rect
                                        v-if="!showRobotPathFlag"
                                        :id="location._element_id + 'rect'"
                                        @click.self="handleSelectOldShelf($event, location)"
                                        @mousedown="handleSelectOldShelf($event, location)"
                                        @mouseup="handleStopDragOldShelf($event, location)"
                                        @mousemove="mouseevent(handleDragOldShelf, $event, location)"
                                        @keyup.stop="handleNudge"

                                        :transform="`rotate(`+(parseFloat(location.rotation)+parseFloat(location._batch_r)+360%360)+`,`+(location.x + location._batch_x + (location.width + location._batch_w)/2) * warehouse._scale_x+`,`+(location.y + location._batch_y + (location.height + location._batch_h)/2) * warehouse._scale_y+`) translate(`+(location.x + location._batch_x) * warehouse._scale_x+`,`+(location.y + location._batch_y) * warehouse._scale_y+`)`"
                                        :width="(location.width + location._batch_w) * warehouse._scale_x"
                                        :height="(location.height + location._batch_h) * warehouse._scale_y"
                                        :fill="location._search_select ? `yellow` : (location === drag_obj && drag_will_trash ? `red` : (location === shelf_selected ? `lightblue` : (location === shelf_selected_nudge ? `pink` : location.amountAlertFlag ? `#FFCF71` : location.daysAlertFlag ? `#FF8B7B` : location._fill)))"
                                        :stroke="item_target === location ? `red` : `black`"
                                        :stroke-width="item_target === location ? line_width * 2 : line_width"
                                        :stroke-dasharray="item_target === location ? `` : (line_width*2 + ` ` +line_width*2)"
                                        :fill-opacity="location._search_select ? 1 : warehouse.shelf_transparency"
                                        class="shelf"
                                        :data-tabindex="index"
                                />

                                <rect v-else
                                        :id="location._element_id + 'rect'"
                                        :transform="`rotate(`+(parseFloat(location.rotation)+parseFloat(location._batch_r)+360%360)+`,`+(location.x + location._batch_x + (location.width + location._batch_w)/2) * warehouse._scale_x+`,`+(location.y + location._batch_y + (location.height + location._batch_h)/2) * warehouse._scale_y+`) translate(`+(location.x + location._batch_x) * warehouse._scale_x+`,`+(location.y + location._batch_y) * warehouse._scale_y+`)`"
                                        :width="(location.width + location._batch_w) * warehouse._scale_x"
                                        :height="(location.height + location._batch_h) * warehouse._scale_y"
                                        :fill="location._current_search && location._old_search ? `grey` : location._current_search && !location._old_search ? `yellow` : location._fill"
                                        :stroke="item_target === location ? `red` : `black`"
                                        :stroke-width="item_target === location ? line_width * 2 : line_width"
                                        :stroke-dasharray="item_target === location ? `` : (line_width*2 + ` ` +line_width*2)"

                                        class="shelf"
                                        :data-tabindex="index"
                                        
                                />
                                <text
                                        v-if="!showRobotPathFlag && ((!drag_mode || drag_obj === location) && item_scale < 0.03 || serializing )"
                                        @click.self="handleSelectOldShelf($event, location)"
                                        @mousedown="handleSelectOldShelf($event, location)"
                                        @mouseup="handleStopDragOldShelf($event, location)"
                                        @mousemove="mouseevent(handleDragOldShelf, $event, location)"
                                        @keyup.stop="handleNudge"
                                        :transform="`translate(`+(location.x + location._batch_x + location.width/2) * warehouse._scale_x+`,`+(location.y + location._batch_y + location.height/2 + item_scale * 7) * warehouse._scale_y+`) scale(`+item_scale+`,`+item_scale+`)`"
                                        :style="`font-size: 0.5rem; pointer-events: none;`+capabilities.location_select_only?`opacity:0.2`:``"
                                        text-anchor="middle"
                                >
                                    {{ location.label }}
                                </text>
                                <text
                                        v-if="showRobotPathFlag && ((!drag_mode || drag_obj === location) && item_scale < 0.03 || serializing )"
                                        :transform="`translate(`+(location.x + location._batch_x + location.width/2) * warehouse._scale_x+`,`+(location.y + location._batch_y + location.height/2 + item_scale * 7) * warehouse._scale_y+`) scale(`+item_scale+`,`+item_scale+`)`"
                                        :style="`font-size: 0.5rem; pointer-events: none;`+capabilities.location_select_only?`opacity:0.2`:``"
                                        text-anchor="middle"
                                >
                                    {{ location.label }}
                                </text>
                            </g>
                            </template>

                            <template v-if="!capabilities.location_select_only && item_scale < 0.06">
                                <template v-for="tag in warehouse.tags">
                                <g
                                        v-if="tag._status !== `delete` && tag._status !== `ignore` && (serializing || ( visible_area.top < (tag.y + tag._batch_y) && visible_area.left < (tag.x + tag._batch_x) && visible_area.bottom > (tag.y + tag._batch_y) && visible_area.right > (tag.x + tag._batch_x)))"
                                        v-bind:key="tag._element_id"
                                        :transform="`translate(`+((tag.x + tag._batch_x) * warehouse._scale_x - item_scale*12)+`,`+(tag.y + tag._batch_y) * warehouse._scale_y+`)`"
                                        @mousedown="handleSelectOldItem($event, tag)"
                                        @mouseup="handleStopDragOldShelf($event, tag)"
                                        @mousemove="mouseevent(handleDragOldShelf, $event, tag)"
                                        @keyup.stop="handleNudge"
                                        :style="item_mode || tag._location === shelf_selected ? `pointer-events: all;`:`pointer-events: none;`"
                                >
                                    <location-tag
                                            :transform="`scale(`+item_scale+`,`+item_scale+`) rotate(45 12.00000000000001,14.499771118164059)`"
                                            :fill="item_selected === tag ? `red`:`black`"
                                            :_style="item_mode || tag._location === shelf_selected ? `pointer-events: all;`:`pointer-events: none;`"
                                    />
                                    <text
                                            :transform="`scale(`+item_scale+`,`+item_scale+`) translate(12.5,35)`"
                                            style="font-size: 0.8rem; pointer-events: none;"
                                            text-anchor="middle"
                                    >
                                        {{ tag.label }}
                                    </text>
                                </g>
                                </template>
                            </template>


                            <rect
                                    v-if="drag_mode===`shelf` && drag_obj===null"
                                    :transform="`translate(`+drag_x * warehouse._scale_x +`,`+drag_y * warehouse._scale_y +`)`"
                                    :width="warehouse.default_shelf_width * warehouse._scale_x"
                                    :height="warehouse.default_shelf_height * warehouse._scale_y"
                                    :fill="drag_trash?`red`:`lightgreen`"
                                    stroke="black"
                                    :stroke-width="line_width"
                                    :stroke-dasharray="line_width*2 + ` ` +line_width*2"
                                    style="fill-opacity:0.8;stroke-opacity:0.9"
                                    class="no-panzoom shelf"
                            />

                            <g
                                    v-if="drag_mode===`item` && drag_obj===null && drag_x > 0 && drag_y > 0"
                                    :transform="`translate(`+(drag_x * warehouse._scale_x - this.item_scale*12)+`,`+(drag_y * warehouse._scale_y)+`)`"
                            >
                                <location-tag
                                        :transform="`scale(`+item_scale+`,`+item_scale+`) rotate(45 12.00000000000001,14.499771118164059)`"
                                />
                                <text
                                        :transform="`scale(`+item_scale+`,`+item_scale+`) translate(12.5,35)`"
                                        style="font-size: 0.8rem"
                                        text-anchor="middle"
                                >
                                    {{ item_next_index }}
                                </text>
                            </g>
                            <rect
                                    v-if="map_selected"
                                    :width="warehouse._scale_fit_x"
                                    :height="warehouse._scale_fit_y"
                                    stroke="#ffcf4c"
                                    fill="#ffcf4c"
                                    fill-opacity="0.4"
                                    :stroke-width="line_width * 3"
                                    @click="handleDeselectMap"
                                    @mouseup="handleStopDragOldShelf($event, null)"
                            />
                            <g
                                    v-if="map_selected"
                            >
                                <path
                                        v-if="map_selected"
                                        :transform="`translate(-`+line_width+`,-`+line_width+`)`"
                                        :d="`m0,0 l`+handlebar_width+`,0 0,`+(handlebar_width*0.2)+` `+(-handlebar_width*0.8)+`,0 0,`+(handlebar_width*0.8)+` `+(-handlebar_width*0.2)+`,0 0,-`+handlebar_width"
                                        stroke="#ffcf4c"
                                        fill="#fff"
                                        fill-opacity="0.9"
                                        :stroke-width="line_width*2"
                                        @mousemove="handleDragOldShelf($event, null)"
                                        @mouseup="handleStopDragOldShelf($event, null)"
                                        @mousedown="handleMapDrag(`left`)"
                                />
                            </g>

                            <g
                                    v-if="map_selected"
                                    :transform="`translate(`+(warehouse._scale_fit_x+line_width)+`,`+(warehouse._scale_fit_y+line_width)+`)`"
                            >
                                <path
                                        :transform="`rotate(180 0,0)`"
                                        :d="`m0,0 l`+handlebar_width+`,0 0,`+(handlebar_width*0.2)+` `+(-handlebar_width*0.8)+`,0 0,`+(handlebar_width*0.8)+` `+(-handlebar_width*0.2)+`,0 0,-`+handlebar_width"
                                        fill="#fff"
                                        fill-opacity="0.9"
                                        stroke="#ffcf4c"
                                        :stroke-width="line_width*2"
                                        @mousemove="handleDragOldShelf($event, null)"
                                        @mouseup="handleStopDragOldShelf($event, null)"
                                        @mousedown="handleMapDrag(`right`)"
                                />
                            </g>
                        </g>

                        <g  v-if="showRobotPathFlag" :transform="`translate(`+warehouse.real_width * (warehouse.border_left_x+warehouse._user_border_left_x)+`,`+warehouse.real_height * (warehouse.border_left_y+warehouse._user_border_left_y)+`)`">
                                
                                <polygon :points="arrowPoints" :transform="robotTransform" fill="red" />
                        </g>
                    </g>

                </svg>
            </panZoom>
            <div v-if="popoverFlag">                          
                <template  v-for="location in warehouse.locations" v-bind:key="location._element_id + location.id">
                    <uiv-Popover v-model="popoverModel[location.id]" :target="'#id' + location._element_id" trigger="hover" @show="popoverShow" @hide="popoverHide"> 
                        <template #popover>
                            <template v-for="current in currentData" v-bind:key="current">
                                <template v-if="popoverShowLocation.length === 0">
                                    <template v-if="(current.amountAlertFlag === true || current.daysAlertFlag === true) && (current.storeLocationIds.includes(location.id) || current.backyardLocationIds.includes(location.id))">
                                        <div style="padding: 10px;">
                                        <div style="display:flex;padding-top:5px;padding-bottom:3px"><div style="min-width:85px;">自社品番</div><div>：</div><div>{{current['Stock.itemCode']}}</div></div>
                                        <div style="display:flex;padding-top:5px;padding-bottom:3px"><div style="min-width:85px">商品名</div><div>：</div><div>{{current['ItemMaster.name']}}</div></div>
                                        <div style="display:flex;padding-top:5px;padding-bottom:3px"><div style="min-width:85px">カテゴリ</div><div>：</div><div>{{current['ItemMaster.p1']}}</div></div>
                                        <div style="display:flex;padding-top:5px;padding-bottom:3px"><div style="min-width:85px">数量</div><div>：</div><div>{{titleData !== undefined && titleData.type === 1 ? current.storeCount : current.backyardCount}}</div></div>
                                        <div style="border-bottom: 1px solid #cecdcd;padding-top:5px;padding-bottom:5px"></div>
                                        </div>
                                    </template>
                                </template>
                                <template v-else>
                                     <template v-if="popoverShowData.length !== 0 && popoverShowData.includes(current['Stock.itemCode'])">
                                        <div style="padding: 10px;">
                                        <div style="display:flex;padding-top:5px;padding-bottom:3px"><div style="min-width:85px;">自社品番</div><div>：</div><div>{{current['Stock.itemCode']}}</div></div>
                                        <div style="display:flex;padding-top:5px;padding-bottom:3px"><div style="min-width:85px">商品名</div><div>：</div><div>{{current['ItemMaster.name']}}</div></div>
                                        <div style="display:flex;padding-top:5px;padding-bottom:3px"><div style="min-width:85px">カテゴリ</div><div>：</div><div>{{current['ItemMaster.p1']}}</div></div>
                                        <div style="display:flex;padding-top:5px;padding-bottom:3px"><div style="min-width:85px">数量</div><div>：</div><div>{{titleData !== undefined && titleData.type === 1 ? current.storeCount : current.backyardCount}}</div></div>
                                        <div style="border-bottom: 1px solid #cecdcd;padding-top:5px;padding-bottom:5px"></div>
                                        </div>
                                    </template>
                                    <template v-else-if="popoverShowData.length === 0 && current.backyardLocationIds.includes(location.id)">
                                        <div style="padding: 10px;">
                                        <div style="display:flex;padding-top:5px;padding-bottom:3px"><div style="min-width:85px;">自社品番</div><div>：</div><div>{{current['Stock.itemCode']}}</div></div>
                                        <div style="display:flex;padding-top:5px;padding-bottom:3px"><div style="min-width:85px">商品名</div><div>：</div><div>{{current['ItemMaster.name']}}</div></div>
                                        <div style="display:flex;padding-top:5px;padding-bottom:3px"><div style="min-width:85px">カテゴリ</div><div>：</div><div>{{current['ItemMaster.p1']}}</div></div>
                                        <div style="display:flex;padding-top:5px;padding-bottom:3px"><div style="min-width:85px">数量</div><div>：</div><div>{{titleData !== undefined && titleData.type === 1 ? current.storeCount : current.backyardCount}}</div></div>
                                        <div style="border-bottom: 1px solid #cecdcd;padding-top:5px;padding-bottom:5px"></div>
                                        </div>
                                    </template>
                                </template>
                               
                                
                            </template>
                        </template>
                    </uiv-Popover>
               </template>
            </div>
        </div>
                
           
    </div>
</template>


<script>
    import { v4 as uuidv4 } from 'uuid';
    import { Warehouse, Location, Tag, Checkpoint } from '../modal/Warehouse'
    import { Drag, Drop } from 'vue3-drag-drop';
    import LocationTag from "./LocationTag.vue";
    import { Storage } from 'aws-amplify';
    import {
        BFormCheckbox
    } from "bootstrap-vue-next";
    import { Popover, Btn } from 'uiv';
    import global from "../../Global.vue";
    import JSZip from 'jszip';

    export default {
        components: {
            LocationTag,
            Drag, Drop,
            BFormCheckbox,
            Popover,
            Btn

        },
        props: {
            titleData:{
                default: undefined,
            },
            floormap: Warehouse,
            mode: String,
            bottomId: String,
            bottomShift: {
                type: Number,
                default: 0,
            },
            maxWidth:{
                type: Number,
                default: null,
            },
            maxHeight:{
                type: Number,
                default: null,
            },
            locationId:{
                default: null,
            },
            searchLocationSet:{
                default: null,
            },
            currentData:{
                type: Array,
                default: null,
            },
            currentCategorie:{
                type: Number,
                default: 1,
            },
            popoverShowLocation: {
                type: Array,
                default: [],
            },
            showZoom: Boolean,
            showRobotPathFlag: Boolean,
            currentJobId: Number,
            showRobotPathBodyFlag: Number,
            url:{
                default: undefined,
            },
        },
        data() {
            return {
                capabilities: {
                    save: true,
                    buttons: true,
                    zoom: false,
                    boundary_only: false,
                    location_select_only: false,
                    zoom_buttons: false,
                    snap: true,
                    counts: true,
                },

                warehouse: null,
                width: 250,
                height: 250,
                offset_x: 0,
                offset_y: 0,
                zoom: null,
                zoom_active: false,
                zoom_timer: null,
                zoom_mode: null,
                line_width: 0.01,
                handlebar_width: 0.01,
                item_scale: 0.001,
                svg: null,
                local: null,
                working: false,
                timer: new Date().getTime(),
                serializing: false,
                count_location: 0,
                count_tag: 0,

                // this value later gets set with the default zoom level so that we can calculate changes in zoom by percent
                zoom_fit: 1,
                visible_area: {
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0
                },

                // current #elements coordinates (in meters) of mouse position
                svg_x: 0,
                svg_y: 0,

                // drag_mode determines how changes in the svg mouse position will affect objects
                drag_mode: null,
                drag_obj: null,
                drag_trash: false,
                drag_will_trash: false,
                drag_oob_x: 0,
                drag_oob_y: 0,
                drag_x: 0,
                drag_y: 0,
                drag_item_x: 0,
                drag_item_y: 0,
                drag_offset_x: 0,
                drag_offset_y: 0,
                drag_obj_origin_x: 0,
                drag_obj_origin_y: 0,
                drag_corner: null,
                drag_bounds: null,

                shelf_selected: null,
                shelf_selected_controls: {
                    x: 0,
                    y: 0,
                    w: 0,
                    h: 0,
                },
                shelf_selecting: null,
                shelf_selected_nudge: null,

                map_selecting: null,
                map_selected: false,
                map_select_flag: false,

                // item controls
                item_mode: false,
                item_next_index: 0,
                item_target: null,
                item_selected: null,

                shared_batch: null,
                shared_batch_target: null,
                shared_batch_operation: null,
                shared_batch_on_end: null,

                allow_drag_new: false,
                signedURL:"",
                popoverFlag: false,
                popoverModel:{},
                currentHoverLocationId:null,
                popoverShowData:[],
                maxZoom: 2000,
                arrowPoints: '0,-0.1 0.1,0 0.04,0 0.04,0.1 -0.04,0.1 -0.04,0 -0.1,0',
                pathLength: 0,
                progress: 0,
                robotTransform: '',
                tmpDataList: [],
                robotPathTimer: null,
            };
        },
        mounted() {
            // prepare the data for map usage
            this.warehouse = this.floormap
            this.getMap()
            this.warehouse.registerChangeCallback(this.regenerateTotals);
            let _this = this;
            setInterval(function () {
                _this.allow_drag_new = true
            },10)
            if (this.mode === 'boundary') {
                this.capabilities.buttons = false
                this.capabilities.save = false
                this.capabilities.zoom = false
                this.capabilities.boundary_only = true
                this.capabilities.zoom_buttons = false
                this.capabilities.counts = false
            } else if (this.mode === `location_select`) {
                this.capabilities.buttons = false
                this.capabilities.save = false
                this.capabilities.zoom = this.showZoom
                this.capabilities.zoom_buttons = this.showZoom
                this.capabilities.location_select_only = true
                this.item_mode = true
                this.capabilities.counts = false
            } else if (this.mode === `temi_mode`) {
                this.capabilities.buttons = false
                this.capabilities.save = false
                this.capabilities.zoom = this.showZoom
                this.capabilities.zoom_buttons = this.showZoom
                this.capabilities.location_select_only = true
                this.item_mode = true
                this.capabilities.counts = false                
            }
            this.working = true
            setTimeout(this.loadWarehouse, 20)
            setTimeout(()=>{
                const homePosition = ["",this.warehouse.robot_home_x,this.warehouse.robot_home_y,0]
                this.setRobotPosition(homePosition, this.warehouse.robot_orientation * Math.PI/2)
            },10)
         },
        watch: {
            showRobotPathBodyFlag:{
                handler(newVal, oldVal) {
                    if(newVal === 0){
                        clearInterval(this.robotPathTimer)
                        this.progress = 0
                        this.robotPathTimer = null
                        this.warehouse.locations.map(location => {
                            location._current_search = false
                            location._old_search = false
                        })
                    } else {
                        this.getTemiPath()
                        this.robotPathTimer = setInterval(()=>{
                            this.animateArrow(this.robotPathTimer)
                        }, 200);
                    }
                }
            },
            locationId: {
                handler(newVal, oldVal) {
                    if (!newVal) newVal = ''
                    this.warehouse.locations.map(_ => {
                        _._search_select = false
                        if(this.titleData !== undefined){
                                _._search_select = (newVal.storeLocationIds !== undefined && newVal.storeLocationIds.includes(_.id)) || (newVal.backyardLocationIds !== undefined && newVal.backyardLocationIds.includes(_.id))
                
                        } else {
                           _._search_select = (newVal === _.id)
                        }                       
                    })
                }
            },
            searchLocationSet:{
                handler(newVal, oldVal) {
                    this.warehouse.locations.map(_ => {
                        _._search_select = false
                        if (newVal === undefined || newVal === null || newVal.size  === 0) {
                            return
                        }
                        _._search_select = (newVal.has(_.id))
                                        
                    })

                }
            },
            currentData: {
                handler(newVal, oldVal) {
                    if (newVal === undefined || newVal.length === 0) {
                        this.warehouse.locations.forEach(_ => {
                        _.amountAlertFlag = false
                        _.daysAlertFlag = false
                        })
                        return
                    }
                    
                    this.warehouse.locations.forEach(_ => {
                        _.amountAlertFlag = false
                        _.daysAlertFlag = false
                        if(this.currentCategorie === 1 || this.currentCategorie === 2){
                            _.amountAlertFlag = newVal.some(i => i.amountAlertFlag === true && (i.storeLocationIds.includes(_.id) || i.backyardLocationIds.includes(_.id)))
                        } 
                        if(this.currentCategorie === 1 || this.currentCategorie === 3){
                            _.daysAlertFlag = newVal.some(i => i.daysAlertFlag === true && (i.storeLocationIds.includes(_.id) || i.backyardLocationIds.includes(_.id)))
                        }
                    })
                }
            },
            popoverShowLocation:{
                handler(newVal, oldVal) {
                     if (newVal === undefined || newVal.length === 0){
                        for(let key  in this.popoverModel){
                            this.popoverModel[key] = false
                        }
                        this.popoverShowData = []
                        return
                     } 
                    this.popoverShowData = []
                    newVal.forEach(item => {
                        this.popoverShowData.push(item['Stock.itemCode'])
                        item.backyardLocationIds.forEach(id=>{
                            this.popoverModel[id] = true
                        })
                    })
                    
                }
            }
        },
        methods: {
             animateArrow(timer) {
                if (this.progress >= this.tmpDataList.length) {
                    clearInterval(timer)
                    return;
                };
                const currentData = this.tmpDataList[this.progress].split(",")
                if(currentData.length != 5){
                    clearInterval(timer)
                    return;
                }
                this.progress += 1; 

                this.warehouse.locations.map(location => {
                    if(currentData[4] != 0 && location.id == currentData[4]){
                        location._current_search = true
                    } else {
                        if(location._current_search){
                            location._old_search = true
                        }
                    }

                })
                this.setRobotPosition(currentData, 0)

            },
            setRobotPosition(currentData, firstRotate){
                const positionX = parseFloat(currentData[1])
                const positionY =  parseFloat(currentData[2])
                const positionRotate =  parseFloat(currentData[3])    
                const rotate = positionRotate +  firstRotate
           
                var angle = 0
                if (rotate < 0) {
                    var rotateTemp = -rotate
                    angle = 180 / Math.PI * rotateTemp
                } else {
                    var rotateTemp = 2 * Math.PI - rotate
                    angle = 180 / Math.PI * rotateTemp
                }
                const mapWidth = this.warehouse.real_width
                const mapHeight = this.warehouse.real_height
                this.robotTransform = `translate(${positionX * mapWidth}, ${positionY * mapHeight}) rotate(${angle})`;
            },

            async getMap(){
                if(this.warehouse.upload !== undefined && this.warehouse.upload !== null){
                    if(this.warehouse.upload.includes("private/")){
                        this.signedURL = await Storage.get(this.warehouse.upload.slice(8), { level: 'private' }); 
                    } else if(this.warehouse.upload.includes("protected/")){
                        this.signedURL = await Storage.get(this.warehouse.upload.slice(10), { level: 'protected' }); 
                    } else if(this.warehouse.upload.includes("public/")){
                        this.signedURL = await Storage.get(this.warehouse.upload.slice(7)); 
                    }
                }
                
            },
            regenerateTotals() {
                this.count_location = this.warehouse.locations.filter(_=>_._status !== `ignore` && _._status !== `delete`).length
                this.count_tag = this.warehouse.tags.filter(_=>_._status !== `ignore` && _._status !== `delete`).length
            },
            mouseevent(method, ...parameters) {
                if (new Date().getTime() - this.timer > 30) {
                    this.timer = new Date().getTime()
                    method(...parameters)
                }
            },
            loadWarehouse() {
                let _this = this
                // this.warehouse.shelves.map(_s=>{
                //     _s.locations.map(_l=> {
                //         _l.warehouse = _this.warehouse
                //         _l.shelf = _s
                //         _this.warehouse.locations.push(_l)
                //     })
                // })
                this.regenerateItemNextIndex()
                this.regenerateTotals()
                this.resizeCanvas()
                this.svg = document.getElementById('svg-map')
                this.local = document.getElementById('elements')
                document.onkeydown = function (e) {
                    if (this.shelf_selected_nudge !== null && e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                        e.view.event.preventDefault();
                    }
                }
                if (!this.capabilities.zoom)
                    this.zoom.pause()

                this.map_selected = this.capabilities.boundary_only
                this.working = false
                setTimeout(_=>{
                    this.popoverFlag = this.titleData !== undefined
                }, 1000)

            },
            undo() {
                Checkpoint.undo(this.warehouse._checkpoints)
                this.regenerateItemNextIndex()
                if (this.item_selected) {
                    this.item_selected_label.label = this.item_selected.label
                }
            },
            toggleItemMode(event) {
                this.item_mode = !this.item_mode
                this.handleDeselectAll(event)
            },
            serialize() {
                this.working = true
                let _this = this
                this.serializing = true
                this.handleDeselectAll()
                this.map_selected = false
                setTimeout(_=>{
                    let output = this.shallowCopy(this.warehouse, [`id`, `name`,`real_width`,`real_height`,`border_left_x`,`border_left_y`,`border_right_x`,`border_right_y`,`default_shelf_height`,`default_shelf_width`])
                    output.locations = []
                    output.tags = []
                    this.warehouse.locations.filter(_=>_._status !== `ignore`).map(_=>output.locations.push(_this.shallowCopy(_, [`id`,`x`,`y`,`width`,`height`,`_element_id`,`label`])))
                    this.warehouse.tags.filter(_=>_._status !== `ignore` && _._status !== `saved`).map(_=>output.tags.push(_this.shallowCopy(_, [`id`,`x`,`y`,`label`,`_location_id`])))
                    output.svg_src = _this.$refs["svg"].outerHTML;
     

                }, 500)
            },
            shallowCopy(source, only) {
                let keys = Object.keys(source)
                let output = {}
                only.push(`_status`)
                for (let i = 0; i < keys.length; i++) {
                    if (only.indexOf(keys[i]) === -1) continue
                    output[keys[i]] = source[keys[i]]
                }
                return output
            },
            updateBounds() {
                this.warehouse.border_left_x  = parseFloat(this.warehouse._user_border_left_x)
                this.warehouse.border_left_y  = parseFloat(this.warehouse._user_border_left_y)
                this.warehouse.border_right_x = parseFloat(this.warehouse._user_border_right_x)
                this.warehouse.border_right_y = parseFloat(this.warehouse._user_border_right_y)
                this.warehouse.calculateScale()
            },
            changeDefaultSizes() {
                this.warehouse.default_shelf_width = parseFloat(this.warehouse._default_shelf_width)
                this.warehouse.default_shelf_height = parseFloat(this.warehouse._default_shelf_height)
            },
            changeLabel(e) {
                this.onAdjust(this)
            },
            handleDeselectMap(event) {
                if (!this.capabilities.boundary_only)
                    this.map_selected = false
            },
            handleDeselectAll(event) {
                console.log('deselect')
                this.clearMapSelecting(event)
                this.shelf_selected_nudge = null
                this.drag_obj = null
                this.shelf_selected = null
                this.shelf_selecting = null
                this.item_selected = null

                this.drag_mode = null
                this.drag_trash = false
                this.drag_oob_x = 0
                this.drag_oob_y = 0

                this.clearSharedBatch()
                if (this.capabilities.zoom)
                    this.zoom.resume()

                this.$emit('deselectall')
            },
            clearSharedBatch() {
                if (this.shared_batch_on_end !== null && this.shared_batch_on_end !== undefined) {
                    this.shared_batch_on_end(this)
                }

                this.shared_batch_target = null
                this.shared_batch_operation = null
                this.shared_batch_on_end = null
                this.shared_batch = null
            },
            getSharedBatch(target, operation, handler) {
                if (target !== this.shared_batch_target || operation !== this.shared_batch_operation) {
                    if (this.shared_batch_on_end !== null && this.shared_batch_on_end !== undefined) {
                        this.shared_batch_on_end(this)
                    }
                    console.log('Target becomes ', target)
                    this.shared_batch_target = target
                    this.shared_batch_operation = operation
                    this.shared_batch_on_end = handler
                    this.shared_batch = uuidv4
                }
                return this.shared_batch
            },
            handleMapSelect(event) {
                if (this.capabilities.location_select_only) return
                if (this.map_selected) return
                console.log('handleMapSelect')
                this.map_selecting = {
                    x: event.x - 20,
                    y: event.y - 20
                }

                this.svgPositionListener(event)

                let _this = this
                if (this.warehouse._timer !== null)
                    clearTimeout(this.warehouse._timer)
                this.warehouse._timer = setTimeout(function () {
                    _this.map_selected = true
                    _this.map_select_flag = true
                    _this.map_selecting = null
                    console.log('MapSelected')
                }, 700)
            },
            handleMapDrag(corner) {
                this.drag_corner = corner
                this.drag_mode = `map_resize`
                this.drag_bounds = this.svgRelativeBounds()
                if (this.capabilities.zoom)
                    this.zoom.pause()
            },
            onAdjust(_this) {
                console.log('Target is ', this.shared_batch_target)
                let target = this.shared_batch_target
                if (!target) {
                    console.log('Target is null, not ready to commit change')
                    return
                }
                let delta = {
                    _status: `change`,
                    _batch: this.shared_batch,
                    x: target.x + target._batch_x,
                    y: target.y + target._batch_y,
                }
                target._batch_x = 0
                target._batch_y = 0

                if (target instanceof Location) {
                    delta.width = target.width + target._batch_w
                    delta.height = target.height + target._batch_h
                    target._batch_w = 0
                    target._batch_h = 0

                    let location_delta = {
                        _status: `change`,
                        _batch: this.shared_batch,
                    }
                    target.tags.filter(_=>_._status !== `ignore` && _._status !== `delete`).map(tag => {
                        location_delta.x = tag.x + tag._batch_x
                        location_delta._batch_x = 0
                        location_delta.y = tag.y + tag._batch_y
                        location_delta._batch_y = 0
                        tag.update(location_delta, `adjust`)
                    })
                }

                if (target instanceof Tag && target.label != this.item_selected_label.label) {
                    let new_label = this.item_selected_label.label.trim()
                    let already_taken = this.warehouse.tags.filter(_=>
                        _.label == new_label && _._element_id !== _this.item_selected_label.item._element_id
                    );
                    if (already_taken.length) {
                        alert('Use a different tag name.')
                        return
                    }
                    if (!/^\d+$/.test(new_label)) {
                        alert('Tag name must be an integer.')
                        return
                    }
                    delta.label = parseInt(new_label)
                }

                target.update(delta, `adjust`)
                if (delta.label !== undefined) {
                    this.regenerateItemNextIndex()
                }
            },
            handleNudge(event) {
                let target = this.shelf_selected_nudge || this.item_selected
                if (target === null) return
                event.preventDefault()
                let container = {
                    x: 0,
                    y: 0,
                    width: this.warehouse.real_width,
                    height: this.warehouse.real_height,
                }
                if (target instanceof Tag) {
                    container = target._location
                }
                this.getSharedBatch(target, `nudge`, this.onAdjust)

                let delta = {
                    x: target.x + target._batch_x,
                    y: target.y + target._batch_y
                }
                if (event.key === 'ArrowUp') {
                    if (target.y - 0.1 < container.y) {
                        delta.y = container.y
                    } else {
                        delta.y = target.y + target._batch_y - 0.1
                    }
                }
                else if (event.key === 'ArrowDown') {
                    if (target.y + 0.1 > container.y + container.height) {
                        delta.y = container.y + container.height
                    } else {
                        delta.y = target.y + target._batch_y + 0.1
                    }
                }
                else if (event.key === 'ArrowLeft') {
                    if (target.x - 0.1 < container.x) {
                        delta.x = container.x
                    } else {
                        delta.x = target.x + target._batch_x - 0.1
                    }
                }
                else if (event.key === 'ArrowRight') {
                    if (target.x + 0.1 > container.x + container.width) {
                        delta.x = container.x + container.width
                    } else {
                        delta.x = target.x + target._batch_x + 0.1
                    }
                }
                target._batch_x = delta.x - target.x
                target._batch_y = delta.y - target.y

                if (target instanceof Location) {
                    target.tags.filter(_=>_._status !== `ignore` && _._status !== `delete`).map(tag => {
                        tag._batch_x = target._batch_x
                        tag._batch_y = target._batch_y
                    })
                }
                //target.update(delta, 'nudge')
                return false
            },
            handleOldShelfDelete (event) {
                this.drag_will_trash = false
                if (this.drag_mode !== `shelf_old` && this.drag_mode !== `item_drag` || this.drag_obj === null) {
                    return
                }

                let delta = {
                    _status: `delete`,
                    _batch_x: this.drag_obj._batch_x,
                    _batch_y: this.drag_obj._batch_y,
                    _batch: uuidv4
                }
                if (this.drag_obj instanceof Tag && this.shelf_selected !== null) {
                    delta._batch_mix = true
                    delta._batch = this.shared_batch || (this.shared_batch = uuidv4)
                }
                this.drag_obj._batch_x = 0
                this.drag_obj._batch_y = 0
                if (this.drag_obj instanceof Location) {
                    this.drag_obj.tags.filter(_=>_._status !== `ignore`).map(_=> {
                        _._batch_x = 0
                        _._batch_y = 0
                        _.update(delta,`trash`)
                    })
                }

                this.drag_obj.update(delta, `trash`)

                this.handleDeselectAll()
                this.regenerateItemNextIndex()
            },
            handleOldShelfDeleteIndicator (event) {
                if (this.drag_mode !== `shelf_old` && this.drag_mode !== `item_drag` || this.drag_obj === null) {
                    this.drag_will_trash = false
                    return
                }
                this.drag_will_trash = true
            },
            handleDragNewShelf(event, type) {
                this.handleDeselectAll()
                this.item_mode = false
                this.drag_mode = type
                this.drag_obj = null
                this.drag_x = 0
                this.drag_y = 0
                this.shelf_selected = null
                this.drag_offset_x = this.warehouse.default_shelf_width / 2
                this.drag_offset_y = this.warehouse.default_shelf_height / 2
                if (this.capabilities.zoom)
                    this.zoom.pause()
            },
            handleSelectOldItem(event, item) {
                if (this.capabilities.location_select_only) return
                // only allow this item to be manipulated if in item edit more or the item is in the currently selected shelf
                if (!this.item_mode && item._location !== this.shelf_selected) return
                this.drag_mode = `item_select`

                this.drag_obj = item
                if (this.capabilities.zoom)
                    this.zoom.pause()

                // force the item to appear on the top of all other items
                this.warehouse.tags.splice(this.warehouse.tags.indexOf(item), 1)
                this.warehouse.tags.push(item)

                this.clearSharedBatch()
                this.item_selected_label = {item: item, label: item.label}
            },
            handleSelectOldShelf(event, shelf) {
                if (this.capabilities.location_select_only) {
                    this.item_selected = shelf
                    this.warehouse.locations.map(_ => {
                        _._search_select = _ === shelf
                    })
                    this.$emit('selectlocation', shelf)
                    // if (this.capabilities.zoom)
                    //     this.zoom.pause()
                    return
                }
                if (this.item_mode) return
                if (this.shelf_selected === shelf) return
                this.shelf_selected = null
                this.drag_mode = `shelf_old`
                this.drag_obj = shelf
                if (this.capabilities.zoom)
                    this.zoom.pause()
                this.shelf_selecting = {
                    x: event.x - 20,
                    y: event.y - 20
                }
                this.clearSharedBatch()
                this.svgPositionListener(event)
                this.drag_offset_x = this.svg_x - shelf.x
                this.drag_offset_y = this.svg_y - shelf.y

                let _this = this
                shelf._timer = setTimeout(function () {
                    _this.shelf_selected = shelf
                    _this.shelf_selecting = null
                    _this.shelf_selected_nudge = null
                    _this.shelf_selected._user_x = _this.shelf_selected.x + _this.shelf_selected._batch_x
                    _this.shelf_selected._user_y = _this.shelf_selected.y + _this.shelf_selected._batch_y
                    _this.shelf_selected._user_w = _this.shelf_selected.width + _this.shelf_selected._batch_w
                    _this.shelf_selected._user_h = _this.shelf_selected.height + _this.shelf_selected._batch_h
                    _this.regenerateShelfSelectedControls()
                }, 700)

                this.warehouse.locations.splice(this.warehouse.locations.indexOf(shelf), 1)
                this.warehouse.locations.push(shelf)

            },
            regenerateShelfSelectedControls() {
                if (this.shelf_selected === null) return
                let ele = document.getElementById(this.shelf_selected._element_id).getBoundingClientRect()
                // console.log(ele)
                this.shelf_selected_controls = {
                    x: ele.x,
                    y: ele.y,
                    w: ele.width,
                    h: ele.height,
                }
            },
            updateShelfFromManualInput() {
                if (this.capabilities.location_select_only) return
                let _this = this
                this.getSharedBatch(this.shelf_selected, `adjust`, this.onAdjust)
                setTimeout(_=> {
                    if (!isNaN(parseFloat(this.shelf_selected._user_x))) {
                        this.shelf_selected._batch_x = this.shelf_selected._user_x - this.shelf_selected.x
                    }
                    if (!isNaN(parseFloat(this.shelf_selected._user_y))) {
                        this.shelf_selected._batch_y = this.shelf_selected._user_y - this.shelf_selected.y
                    }
                    if (!isNaN(parseFloat(this.shelf_selected._user_x))) {
                        this.shelf_selected._batch_w = this.shelf_selected._user_w - this.shelf_selected.width
                    }
                    if (!isNaN(parseFloat(this.shelf_selected._user_h))) {
                        this.shelf_selected._batch_h = this.shelf_selected._user_h - this.shelf_selected.height
                    }

                    setTimeout(_=> {
                        _this.regenerateShelfSelectedControls()
                        _this.shelf_selected.tags.filter(_=>_._status !== `ignore` && _._status !== `delete`).map(tag => {
                            tag._batch_x = this.shelf_selected._batch_x
                            tag._batch_y = this.shelf_selected._batch_y
                        })
                    }, 20)
                },20)

            },
            clearMapSelecting(event) {
                // clear map selecting
                if (this.warehouse._timer) {
                    console.log('stop MapSelect')
                    clearTimeout(this.warehouse._timer)
                    this.warehouse._timer = null
                }
                this.warehouse.locations.map(_ => {
                    _._search_select = false
                })
                this.map_selecting = null
            },
            handleDragOldShelf(event, shelf) {

                if (this.capabilities.location_select_only) return
                this.clearMapSelecting(event)

                if (this.drag_mode === `map_resize`) {
                    this.svgPositionListenerMap(event)
                    let cursor_x = this.svg_x / this.warehouse._scale_x
                    let cursor_y = this.svg_y / this.warehouse._scale_y
                    let range_x = this.warehouse.real_width / this.warehouse._scale_x
                    let range_y = this.warehouse.real_height / this.warehouse._scale_y

                    let distance_x = 0
                    let distance_y = 0

                    switch(this.drag_corner) {
                        case `left`:
                            // determine percentage distance from edge - constrain to left upper side
                            distance_x = Math.min(Math.max(cursor_x / range_x, 0), 0.49 )
                            distance_y = Math.min(Math.max(cursor_y / range_y, 0), 0.49 )

                            this.warehouse._user_border_left_x = (distance_x - this.warehouse.border_left_x)
                            this.warehouse._user_border_left_y = (distance_y - this.warehouse.border_left_y)
                            break;
                        case `right`:
                            // determine percentage distance from edge - constrain to right lower side
                            distance_x = Math.min(Math.max(1 - cursor_x / range_x, 0), 0.49 )
                            distance_y = Math.min(Math.max(1 - cursor_y / range_y, 0), 0.49 )

                            this.warehouse._user_border_right_x = (distance_x - this.warehouse.border_right_x)
                            this.warehouse._user_border_right_y = (distance_y - this.warehouse.border_right_y)
                            break;
                    }

                    this.warehouse.calculateScale()

                    return
                }
                if (this.drag_mode === `item_select`) {
                    // allow the item to be promoted to drag state
                    this.drag_mode = `item_drag`
                    this.drag_obj_origin_x = this.drag_obj.x
                    this.drag_obj_origin_y = this.drag_obj.y
                }
                if (this.drag_mode === `item_drag`) {
                    this.svgPositionListener(event)
                    this.item_selected = null
                    if (this.svg_x < 0 || this.svg_y < 0 || this.svg_x > this.warehouse.real_width || this.svg_y > this.warehouse.real_height) {
                        // out of bounds
                        this.drag_x = 0
                        this.drag_y = 0
                        this.drag_obj._batch_x = 0
                        this.drag_obj._batch_y = 0
                        this.drag_trash = true
                        this.drag_oob_x = event.pageX
                        this.drag_oob_y = event.pageY
                    } else {
                        this.drag_trash = false
                        this.drag_oob_x = 0
                        this.drag_oob_y = 0
                        this.drag_obj._batch_x = this.svg_x - this.drag_obj.x
                        this.drag_obj._batch_y = this.svg_y - this.drag_obj.y
                        let _this = this
                        let _did_set = false
                        this.warehouse.locations.filter(_=>_._status !== `ignore` && _._status !== `delete`).map(s => {
                            if (s.x < _this.svg_x && s.y < _this.svg_y && s.x + s.width > _this.svg_x && s.y + s.height > _this.svg_y) {
                                _this.item_target = s
                                _did_set = true
                            }
                        })
                        if (!_did_set) _this.item_target = null

                    }
                    return
                }
                if (this.item_mode) return
                // document.getElementById('map-container').focus()
                if (this.drag_mode === `shelf_edge`) {
                    this.getSharedBatch(this.shelf_selected, `adjust`, this.onAdjust)
                    this.svgPositionListener(event)
                    let new_x = (this.svg_x - this.shelf_selected.x).toFixed(2)
                    let new_y = (this.svg_y - this.shelf_selected.y).toFixed(2)

                    let max_x = (this.warehouse.real_width - this.shelf_selected.x).toFixed(2)
                    let max_y = (this.warehouse.real_height - this.shelf_selected.y).toFixed(2)

                    this.shelf_selected._batch_w = Math.min(new_x, max_x) - this.shelf_selected.width
                    this.shelf_selected._batch_h = Math.min(new_y, max_y) - this.shelf_selected.height

                    this.shelf_selected._user_w = this.shelf_selected.width + this.shelf_selected._batch_w
                    this.shelf_selected._user_h = this.shelf_selected.height + this.shelf_selected._batch_h

                    this.regenerateShelfSelectedControls()

                    return
                }
                if (this.drag_mode !== `shelf_old`) return
                if (shelf === null) {
                    shelf = this.drag_obj
                }
                if (shelf !== this.drag_obj) return

                this.svgPositionListener(event)
                if (shelf._timer) {
                    clearTimeout(shelf._timer)
                    this.shelf_selecting = null
                    this.drag_obj._timer = null
                    this.shelf_selected_nudge = null
                }

                let dummy_shelf = this.shallowCopy(shelf, [`x`,`y`,`width`,`height`,`_batch_x`,`_batch_y`,`_element_id`])

                let shelf_drag = this.standardizedShelfDrag(dummy_shelf)

                shelf._batch_x = dummy_shelf._batch_x
                shelf._batch_y = dummy_shelf._batch_y

                // if the shelf is out of the map area it needs to be trashable
                if (shelf_drag.oob < 2) {
                    // enable trashing this item
                    this.drag_trash = true
                    this.drag_oob_x = event.pageX
                    this.drag_oob_y = event.pageY
                } else {
                    this.drag_trash = false
                    this.drag_oob_x = 0
                    this.drag_oob_y = 0
                }

                // this will update the child objects so that they move with the shelf
                shelf.tags.map(tag => {
                    tag._batch_x = shelf._batch_x
                    tag._batch_y = shelf._batch_y
                })
            },
            standardizedShelfDrag(shelf) {
                let oob = 0
                let testX1 = this.svg_x - this.drag_offset_x < 0
                let testX2 = this.svg_x + shelf.width - this.drag_offset_x > this.warehouse.real_width
                let testY1 = this.svg_y - this.drag_offset_y < 0
                let testY2 = this.svg_y + shelf.height - this.drag_offset_y > this.warehouse.real_height

                let snap_proximity = 0.2
                let snap_neighbor_proximity = Math.max(shelf.width, 2) + 0.01
                let closest_right = {x: 0, d: Infinity, shelf: null}
                let closest_left =  {x: this.warehouse.real_width - shelf.width, d: Infinity, shelf: null}
                let closest_top = {y: 0, d: Infinity, shelf: null}
                let closest_bottom =  {y: this.warehouse.real_height - shelf.height, d: Infinity, shelf: null}
                if (this.capabilities.snap)
                    this.warehouse.locations
                        .filter(loc=> {
                            if (loc._element_id === shelf._element_id) return false; // we dont compare to the shelf being dragged
                            loc._search_select = false

                            let d = []
                            d.push(Math.sqrt(Math.pow(Math.abs(loc.x - this.svg_x),2) + Math.pow(Math.abs(loc.y - this.svg_y ),2)))
                            d.push(Math.sqrt(Math.pow(Math.abs(loc.x + loc.width - this.svg_x),2) + Math.pow(Math.abs(loc.y + loc.height - this.svg_y ),2)))
                            d.push(Math.sqrt(Math.pow(Math.abs(loc.x + loc.width - this.svg_x),2) + Math.pow(Math.abs(loc.y - this.svg_y ),2)))
                            d.push(Math.sqrt(Math.pow(Math.abs(loc.x - this.svg_x),2) + Math.pow(Math.abs(loc.y + loc.height - this.svg_y ),2)))

                            return Math.min( ...d ) < snap_neighbor_proximity
                        })
                        .map(loc=>{
                            // X coordinate
                            // calculate regular right snap
                            let right_coord = loc.x + loc.width
                            let right_distance = Math.abs(right_coord - parseFloat((this.svg_x - this.drag_offset_x).toFixed(2)))

                            // right_distance = right_distance < snap_proximity
                            if (right_distance < snap_proximity && right_distance < closest_right.d) {
                                closest_right.x = shelf.x - right_coord
                                closest_right.d = right_distance
                                closest_right.shelf = loc
                            }

                            // calculate regular left snap
                            let left_coord = loc.x
                            let left_distance = Math.abs(left_coord - parseFloat((this.svg_x - this.drag_offset_x + shelf.width).toFixed(2)))

                            // right_distance = right_distance < snap_proximity
                            if (left_distance < snap_proximity && left_distance < closest_left.d) {
                                closest_left.x = shelf.x - left_coord + shelf.width
                                closest_left.d = right_distance
                                closest_left.shelf = loc
                            }

                            // calculate opposite right snap
                            right_coord = loc.x
                            right_distance = Math.abs(right_coord - parseFloat((this.svg_x - this.drag_offset_x).toFixed(2)))

                            // right_distance = right_distance < snap_proximity
                            if (right_distance < snap_proximity && right_distance < closest_right.d) {
                                closest_right.x = shelf.x - right_coord
                                closest_right.d = right_distance
                                closest_right.shelf = loc
                            }

                            // calculate opposite left snap
                            left_coord = loc.x + loc.width
                            left_distance = Math.abs(left_coord - parseFloat((this.svg_x - this.drag_offset_x + shelf.width).toFixed(2)))

                            // right_distance = right_distance < snap_proximity
                            if (left_distance < snap_proximity && left_distance < closest_left.d) {
                                closest_left.x = shelf.x - left_coord + shelf.width
                                closest_left.d = right_distance
                                closest_left.shelf = loc
                            }

                            // Y coordinate
                            // calculate regular top snap
                            let top_coord = loc.y + loc.height
                            let top_distance = Math.abs(top_coord - parseFloat((this.svg_y - this.drag_offset_y).toFixed(2)))

                            // top_distance = top_distance < snap_proximity
                            if (top_distance < snap_proximity && top_distance < closest_top.d) {
                                closest_top.y = shelf.y - top_coord
                                closest_top.d = top_distance
                                closest_top.shelf = loc
                            }

                            // calculate regular bottom snap
                            let bottom_coord = loc.y
                            let bottom_distance = Math.abs(bottom_coord - parseFloat((this.svg_y - this.drag_offset_y + shelf.height).toFixed(2)))

                            // top_distance = top_distance < snap_proximity
                            if (bottom_distance < snap_proximity && bottom_distance < closest_bottom.d) {
                                closest_bottom.y = shelf.y - bottom_coord + shelf.height
                                closest_bottom.d = top_distance
                                closest_bottom.shelf = loc
                            }

                            // calculate opposite top snap
                            top_coord = loc.y
                            top_distance = Math.abs(top_coord - parseFloat((this.svg_y - this.drag_offset_y).toFixed(2)))

                            // top_distance = top_distance < snap_proximity
                            if (top_distance < snap_proximity && top_distance < closest_top.d) {
                                closest_top.y = shelf.y - top_coord
                                closest_top.d = top_distance
                                closest_top.shelf = loc
                            }

                            // calculate opposite bottom snap
                            bottom_coord = loc.y + loc.height
                            bottom_distance = Math.abs(bottom_coord - parseFloat((this.svg_y - this.drag_offset_y + shelf.height).toFixed(2)))

                            // top_distance = top_distance < snap_proximity
                            if (bottom_distance < snap_proximity && bottom_distance < closest_bottom.d) {
                                closest_bottom.y = shelf.y - bottom_coord + shelf.height
                                closest_bottom.d = top_distance
                                closest_bottom.shelf = loc
                            }
                        })
                // calculate optimal snap
                let x_snap = closest_right.d < closest_left.d ? closest_right : closest_left
                let y_snap = closest_top.d < closest_bottom.d ? closest_top : closest_bottom

                if (testX1 || testX2) {
                    // out of bounds
                    this.drag_x = testX2 ? this.warehouse.real_width - shelf.width : 0
                } else {
                    let new_x = parseFloat((this.svg_x - this.drag_offset_x).toFixed(2)) - shelf.x


                    // if snap != Infinity then apply
                    if (x_snap.d < Infinity) {
                        testX1 = shelf.x - x_snap.x < 0
                        testX2 = shelf.x - x_snap.x + shelf.width > this.warehouse.real_width
                        if (!testX1 && !testX2) {
                            new_x = -x_snap.x
                            //x_snap.shelf._search_select = true
                        }
                    }

                    shelf._batch_x = new_x
                    oob = 1
                }
                if (testY1 || testY2) {
                    // out of bounds
                    this.drag_x = testY2 ? this.warehouse.real_height - shelf.height : 0
                } else {
                    let new_y = parseFloat((this.svg_y - this.drag_offset_y).toFixed(2)) - shelf.y

                    // if snap != Infinity then apply
                    if (y_snap.d < Infinity) {
                        testY1 = shelf.x - y_snap.x < 0
                        testY2 = shelf.x - y_snap.x + shelf.width > this.warehouse.real_width
                        if (!testY1 && !testY2) {
                            new_y = -y_snap.y
                            //y_snap.shelf._search_select = true
                        }
                    }

                    shelf._batch_y = new_y
                    oob += 1
                }
                shelf.oob = oob
                return shelf;
            },
            regenerateItemNextIndex() {
                let max = 0
                this.warehouse.tags.map(_=> {
                    if (max < _.label && _._status !== `delete` && _._status !== `ignore`)
                        max = _.label
                })
                this.item_next_index = max+1
            },
            handleStopDragNewShelf(e, shelf) {
                if (this.capabilities.location_select_only) return
                if (this.drag_mode === `item`) {
                    if (this.svg_x < 0 || this.svg_y < 0 || this.svg_x > this.warehouse.real_width || this.svg_y > this.warehouse.real_height || this.item_target === null) {
                        // out of bounds
                        this.drag_x = 0
                        this.drag_y = 0
                    } else {
                        let tag = new Tag({
                            id: 0,
                            x: this.svg_x,
                            y: this.svg_y,
                            label: this.item_next_index
                        }, `new`)
                        tag.location = this.item_target // setting the shelf will trigger offset recalculation
                        tag.warehouse = this.warehouse
                        this.item_target.tags.push(tag)
                        this.warehouse.tags.push(tag)
                        tag.update({
                            _status: `new`,
                            _batch: uuidv4,
                            _location_id: this.item_target._element_id,
                            x: this.svg_x,
                            y: this.svg_y,
                            label: this.item_next_index
                        }, `add`)
                        this.regenerateItemNextIndex()
                    }
                    this.item_target = null
                    this.drag_mode = null
                    this.drag_obj = null
                    this.drag_trash = false
                    this.drag_oob_x = 0
                    this.drag_oob_y = 0

                    if (this.capabilities.zoom)
                        this.zoom.resume()


                    return
                }
                if (this.item_mode) return
                if (this.drag_trash) {
                    // out of bounds
                    this.drag_x = 0
                    this.drag_y = 0
                } else {
                    let s = new Location({
                        id: 0,
                        x: this.drag_x,
                        y: this.drag_y,
                        width: this.warehouse.default_shelf_width,
                        height: this.warehouse.default_shelf_height,
                    }, `new`)
                    s.warehouse = this.warehouse
                    this.warehouse.locations.push(s)

                    let uuid = uuidv4
                    s.update({
                        _status: `new`,
                        _batch: uuid,
                        x: this.drag_x,
                        y: this.drag_y,
                        width: this.warehouse.default_shelf_width,
                        height: this.warehouse.default_shelf_height,
                        label: `棚`+this.item_next_index,
                    }, `add`)

                    let loc = new Tag({
                        id: 0,
                        x: s.x + this.warehouse.default_shelf_width/2,
                        y: s.y + this.warehouse.default_shelf_height/2,
                        label: this.item_next_index,
                        _location_id: s._element_id
                    }, `new`)
                    loc.location = s // setting the shelf will trigger offset recalculation
                    loc.warehouse = this.warehouse
                    this.warehouse.tags.push(loc)
                    loc.update({
                        _status: `new`,
                        _batch: uuid,
                        _location_id: s._element_id,
                        x: loc.x,
                        y: loc.y,
                        label: this.item_next_index
                    }, `add`)
                    this.regenerateItemNextIndex()
                }
                this.drag_mode = null
                this.drag_obj = null
                this.drag_trash = false
                this.drag_oob_x = 0
                this.drag_oob_y = 0
                this.warehouse.locations.map(_=> {
                    _._search_select = false
                })
                if (this.capabilities.zoom)
                    this.zoom.resume()
            },
            handleStopDragOldShelf(e, shelf) {
                if (this.drag_mode === `map_resize`) {
                    console.log('stop drag')
                    this.drag_mode = null
                    this.drag_corner = null
                    this.drag_bounds = null
                    let delta = {
                        _status: `changed`,
                        _batch: this.getSharedBatch(this.warehouse, `map`),
                        border_left_x      : this.warehouse._user_border_left_x + this.warehouse.border_left_x,
                        border_left_y      : this.warehouse._user_border_left_y + this.warehouse.border_left_y,
                        border_right_x     : this.warehouse._user_border_right_x + this.warehouse.border_right_x,
                        border_right_y     : this.warehouse._user_border_right_y + this.warehouse.border_right_y
                    }
                    this.warehouse._user_border_left_x      = 0
                    this.warehouse._user_border_left_y      = 0
                    this.warehouse._user_border_right_x     = 0
                    this.warehouse._user_border_right_y     = 0

                    this.warehouse.update(delta, `map`)
                    if (this.capabilities.zoom)
                        this.zoom.resume()
                    return
                }
                if (this.drag_mode === `item_select`) {
                    // allow tag to be selected
                    this.item_selected = this.drag_obj
                    this.item_target = null
                    this.drag_mode = null
                    this.drag_obj = null
                    this.drag_trash = false
                    this.drag_oob_x = 0
                    this.drag_oob_y = 0
                    if (this.capabilities.zoom)
                        this.zoom.resume()
                    return
                }
                else if (this.drag_mode === `item_drag`) {
                    if ((this.drag_obj._batch_x === 0 && this.drag_obj._batch_y === 0) || this.svg_x < 0 || this.svg_y < 0 || this.svg_x > this.warehouse.real_width || this.svg_y > this.warehouse.real_height || this.item_target === null) {
                        // out of bounds
                        this.drag_x = 0
                        this.drag_y = 0
                        this.drag_obj.x = this.drag_obj_origin_x
                        this.drag_obj.y = this.drag_obj_origin_y
                    } else {
                        let delta = {
                            _status: 'change',
                            x: this.drag_obj.x + this.drag_obj._batch_x,
                            y: this.drag_obj.y + this.drag_obj._batch_y,
                            _batch_x: 0,
                            _batch_y: 0,
                            _location_id: this.item_target._element_id,
                            _batch: this.shared_batch || (this.shared_batch = uuidv4),
                        }
                        this.drag_obj._batch_x = 0
                        this.drag_obj._batch_y = 0
                        this.drag_obj.update(delta, `drag`)

                        // do not remove from old parent if old parent doesnt exist
                        if (this.drag_obj._location)
                            this.drag_obj._location.tags.splice(this.drag_obj._location.tags.indexOf(shelf), 1)

                        this.drag_obj._location = this.item_target // setting the shelf will trigger offset recalculation
                        this.drag_obj.warehouse = this.warehouse
                        this.item_target.tags.push(this.drag_obj)
                        this.drag_obj.calculateOffset()
                        this.regenerateItemNextIndex()
                    }
                    this.item_target = null
                    this.drag_mode = null
                    this.drag_obj = null
                    this.drag_trash = false
                    this.drag_oob_x = 0
                    this.drag_oob_y = 0
                    if (this.capabilities.zoom)
                        this.zoom.resume()
                    return
                }
                if (this.item_mode) return

                if (this.drag_obj && this.drag_obj._timer) {
                    console.log('select nudge')
                    clearTimeout(this.drag_obj._timer)
                    this.shelf_selecting = null
                    this.shelf_selected_nudge = shelf
                    this.drag_mode = null
                    this.drag_obj = null
                    this.drag_trash = false
                    this.drag_oob_x = 0
                    this.drag_oob_y = 0
                    if (this.capabilities.zoom)
                        this.zoom.resume()
                    return
                }
                if (this.drag_obj === null) {
                    // if (!this.shelf_selected)
                    //     this.handleDeselectAll(e)
                    return
                }

                this.drag_obj._fill = 'lightgreen'

                let delta = {
                    _status: 'change',
                    x: this.drag_obj.x + this.drag_obj._batch_x,
                    y: this.drag_obj.y + this.drag_obj._batch_y,
                    _batch_x: 0,
                    _batch_y: 0,
                    _batch: uuidv4,
                }
                this.drag_obj.update(delta, `drag`)
                this.drag_obj.tags.filter(_=>_._status !== `ignore`).map(tag => {
                    delta.x = tag.x + tag._batch_x
                    delta.y = tag.y + tag._batch_y
                    tag.update(delta, `drag`)
                })
                this.drag_mode = null
                this.drag_obj = null
                this.drag_trash = false
                this.drag_oob_x = 0
                this.drag_oob_y = 0
                this.warehouse.locations.map(_=> {
                    _._search_select = false
                })
                if (this.capabilities.zoom)
                    this.zoom.resume()
            },
            handleDragover(event) {
                //if (this.item_mode) return
                if (!this.item_mode && this.drag_mode === `shelf`) {
                    if (!this.allow_drag_new) return
                    this.allow_drag_new = false
                    this.svgPositionListener(event)

                    // let oob = 0
                    // let testX1 = this.svg_x - this.drag_offset_x < 0
                    // let testX2 = this.svg_x + this.warehouse.default_shelf_width - this.drag_offset_x > this.warehouse.real_width
                    // let testY1 = this.svg_y - this.drag_offset_y < 0
                    // let testY2 = this.svg_y + this.warehouse.default_shelf_height - this.drag_offset_y > this.warehouse.real_height
                    //
                    // if (testX1 || testX2) {
                    //     // out of bounds
                    //     this.drag_x = testX2 ? this.warehouse.real_width - this.warehouse.default_shelf_width : 0
                    // } else {
                    //     this.drag_x = parseFloat((this.svg_x - this.drag_offset_x).toFixed(2))
                    //     oob = 1
                    // }
                    // if (testY1 || testY2) {
                    //     // out of bounds
                    //     this.drag_y = testY2 ? this.warehouse.real_height - this.warehouse.default_shelf_height : 0
                    // } else {
                    //     this.drag_y = parseFloat((this.svg_y - this.drag_offset_y).toFixed(2))
                    //     oob += 1
                    // }

                    let dummy_shelf = {
                        x: 0,
                        y: 0,
                        width: this.warehouse.default_shelf_width,
                        height: this.warehouse.default_shelf_height,
                        _batch_x: 0,
                        _batch_y: 0,
                        _element_id: 0,
                    }
                    let shelf_drag = this.standardizedShelfDrag(dummy_shelf)

                    this.drag_x = shelf_drag._batch_x
                    this.drag_y = shelf_drag._batch_y

                    if (shelf_drag.oob < 2 && this.svg_y < 0 || this.svg_x < 0 || this.svg_y > this.warehouse.real_height || this.svg_x > this.warehouse.real_width) {
                        this.drag_trash = true
                        this.drag_oob_x = event.pageX
                        this.drag_oob_y = event.pageY

                    } else {
                        this.drag_trash = false
                        this.drag_oob_x = 0
                        this.drag_oob_y = 0
                    }

                } else if (this.drag_mode === `item`) {
                    this.svgPositionListener(event)
                    if (this.svg_x < 0 || this.svg_y < 0 || this.svg_x > this.warehouse.real_width || this.svg_y > this.warehouse.real_height) {
                        // out of bounds
                        this.drag_x = 0
                        this.drag_y = 0
                        this.drag_trash = true
                        this.drag_oob_x = event.pageX
                        this.drag_oob_y = event.pageY
                    } else {
                        this.drag_trash = false
                        this.drag_oob_x = 0
                        this.drag_oob_y = 0
                        this.drag_x = this.svg_x
                        this.drag_y = this.svg_y
                        let _this = this
                        let _did_set = false
                        this.warehouse.locations.map(s => {
                            if (s.x < _this.drag_x && s.y < _this.drag_y && s.x + s.width > _this.drag_x && s.y + s.height > _this.drag_y) {
                                _this.item_target = s
                                _did_set = true
                            }
                        })
                        if (!_did_set) _this.item_target = null
                    }
                }
            },
            disabledEvent(e) {
                e.preventDefault()
                return false
            },
            initPanzoom(e) {
                this.zoom = e
                //e.pause()
                // document.getElementById('map').removeEventListener('touchstart', onTouch)
            },
            updateVisible(e) {
                let transform = e.getTransform()
                let scene = document.getElementById('scene').getBoundingClientRect()
                
                if(this.width - scene.width >= 0){
                    if(transform.x < 0){
                        transform.x = 0
                    }
                    if(transform.x > this.width - scene.width){
                        transform.x = this.width - scene.width
                    }
                }else {
                    if(transform.x > 0){
                        transform.x = 0
                    }
                    if(transform.x < this.width - scene.width){
                        transform.x = this.width - scene.width
                    }
                }
                if(this.height - scene.height >= 0){
                    if(transform.y < 0){
                        transform.y = 0
                    }
                    if(transform.y > this.height - scene.height){
                        transform.y = this.height - scene.height
                    }
                }else {
                    if(transform.y >= 0){
                        transform.y = 0
                    }
                    if(transform.y < this.height - scene.height){
                        transform.y = this.height - scene.height
                    }
                }
                let bounds = {
                    left: (-this.line_width * transform.x - this.warehouse.real_width * this.warehouse.border_left_x) / this.warehouse._scale_x,
                    top: (-this.line_width * transform.y - this.warehouse.real_height * this.warehouse.border_left_y)  / this.warehouse._scale_y,
                }

                bounds.right = bounds.left + (this.width * this.line_width + this.warehouse.real_width * this.warehouse.border_left_x) / this.warehouse._scale_x
                bounds.bottom = bounds.top + (this.height * this.line_width + this.warehouse.real_height * this.warehouse.border_left_y) / this.warehouse._scale_y

                if (bounds.left < 0) bounds.left = 0
                if (bounds.top < 0) bounds.top = 0
                //console.log(bounds, this.width * this.line_width)
                this.visible_area = bounds
            },
            updateLines(e) { // keeps the lines drawn to 1px
            
                this.updateVisible(e)
                let currentScale = e.getTransform().scale
                let regularZoom = this.width / (this.warehouse.real_width * (1 + this.warehouse.border_left_x + this.warehouse.border_right_x))
                this.line_width = regularZoom / currentScale * (this.warehouse.real_width * (1 + this.warehouse.border_left_x + this.warehouse.border_right_x)) / this.width
                this.handlebar_width = this.line_width * 40
                this.item_scale = 1/(0.85/this.line_width)
                // console.log(e.getTransform())
                // this.regenerateShelfSelectedControls()
            },
            svgPositionListener(e, disable_scale) {
                let
                    x = e.clientX,
                    y = e.clientY,
                    // svgP = this.svgPoint(this.svg, x, y), // page space
                    svgL = this.svgPoint(this.local, x, y); // local space

                if (disable_scale === true) {
                    this.svg_x = parseFloat(svgL.x.toFixed(2))
                    this.svg_y = parseFloat(svgL.y.toFixed(2))
                } else {
                    this.svg_x = parseFloat((svgL.x / this.warehouse._scale_x).toFixed(2))
                    this.svg_y = parseFloat((svgL.y / this.warehouse._scale_y).toFixed(2))
                }

            },
            svgPositionListenerMap(e) {
                let
                    x = e.clientX,
                    y = e.clientY,
                    // svgP = this.svgPoint(this.svg, x, y), // page space
                    svgL = this.svgPoint(document.getElementById('scene'), x, y); // local space

                this.svg_x = parseFloat((svgL.x).toFixed(2))
                this.svg_y = parseFloat((svgL.y).toFixed(2))


            },
            svgRelativeBounds() {
                let scene = document.getElementById('scene').getBoundingClientRect()
                let svg1 = this.svgPoint(this.local, scene.x, scene.y); // local space
                let svg2 = this.svgPoint(this.local, scene.x + scene.width, scene.y + scene.height); // local space

                return {
                    x1: parseFloat((svg1.x / this.warehouse._scale_x).toFixed(2)),
                    y1: parseFloat((svg1.y / this.warehouse._scale_y).toFixed(2)),
                    x2: parseFloat((svg2.x / this.warehouse._scale_x).toFixed(2)),
                    y2: parseFloat((svg2.y / this.warehouse._scale_y).toFixed(2))
                }
            },
            svgPoint(element, x, y) {
                var pt = this.svg.createSVGPoint();
                pt.x = x;
                pt.y = y;
                return pt.matrixTransform(element.getScreenCTM().inverse());
            },
            resizeCanvas() {
                // compares the top of the svg to the top of footer to determine the available screen area for the map
                // if the map has a table below it or something, this will need to be changed a bit
                let _this = this
                let footer = this.bottomId ? document.getElementById(this.bottomId) : document.getElementsByClassName('footer')[0]
                let panzoomObj = document.getElementById('panzoom')
                setTimeout(_=>{
                    if(this.titleData !== undefined){
                        _this.width =  _this.maxWidth !== null ? _this.maxWidth : _this.maxHeight * this.floormap.width / this.floormap.height
                    } else {
                        _this.width =  _this.maxWidth !== null ? _this.maxWidth : panzoomObj.offsetWidth
                    }
                    _this.height = _this.maxHeight !== null ? _this.maxHeight : footer.getBoundingClientRect().top - panzoomObj.getBoundingClientRect().top + this.bottomShift
                    _this.offset_x = (_this.width - _this.warehouse.width) / 2
                    _this.offset_y = (_this.height - _this.warehouse.height) / 2
                    setTimeout(_this.fitWarehouseToZoom, 10)
                }, 500)
            },
            handleZoom(mode, mousedown) {
                let _this = this
                this.zoom_mode = mode
                let doZoom = (delay = 1000) => {
                    if (!_this.zoom_active) return
                    let transform = document.zoomm.getTransform()
                    let ratio = 0
                    if (_this.zoom_mode === `in`) {
                        ratio =  0.1
                    } else {
                        ratio = -0.1
                    }
                    transform.scale += ratio =_this.zoom_fit * ratio
                    if (transform.scale > 1 && transform.scale < this.maxZoom) {
                        if (_this.zoom_mode === `in`) {
                            this.zoom.zoomTo(transform.x, transform.y, 1.1)
                        } else {
                            this.zoom.zoomTo(transform.x, transform.y, 0.9)

                        }
                        if (!_this.zoom_active) return
                        setTimeout(doZoom, delay, 100)
                    }
                }
                if (mousedown) {
                    this.zoom_active = true
                    if (this.zoom_timer !== null)
                        clearTimeout(this.zoom_timer)
                    this.zoom_timer = doZoom()
                } else {
                    this.zoom_active = false
                }
            },
            fitWarehouseToZoom() {
                if (this.zoom === null) return setTimeout(this.fitWarehouseToZoom, 100)

                // for some reason need to move twice...
                this.zoom.moveTo(0, 0);
                this.zoom_fit = Math.min(this.width / this.warehouse.real_width, this.height / this.warehouse.real_height)
                this.zoom.zoomTo(0, 0, this.zoom_fit)
                this.zoom.moveTo(0, 0);
                document.zoomm = this.zoom
            },
            mouseover(location){
                this.currentHoverLocationId = location.id
            },
            popoverShow(){
                // console.log(this.popoverModel)
                if(this.titleData !== undefined && this.titleData.type === 1){
                    
                    const currentPopoverData = this.currentData.filter(item=> item.amountAlertFlag === true && item.storeLocationIds.includes(this.currentHoverLocationId)).filter(item=> item.storeCount < item.amountThreshold)
                    
                    this.$emit("popoverShowLoaction", currentPopoverData);
                }
            },
            popoverHide(){
                var hasPopover = false
                for(let key in this.popoverModel){
                    if(this.popoverModel[key] === true){
                        hasPopover = true
                    }
                }
                if(hasPopover === false){
                    this.$emit("popoverShowLoaction", []);
                }
                
            },
            getTemiPath(){
                const tenantId = localStorage.getItem(global.tenantId)
                
                Storage.list(tenantId + "/robot/" + this.url.warehouse_id + "/" + this.url.menu_id + "/" + this.currentJobId, { pageSize: 'ALL'})
                .then(async ({ results }) => {
                    if(results !== undefined && results.length > 0){
                        var signedURL = await Storage.get(results[0].key, {
                            download: true
                        });
                        const zip = await JSZip.loadAsync(signedURL.Body);
                        const csvFiles = Object.keys(zip.files).filter(filename => filename.endsWith('.csv'));

                        const csvPromises = csvFiles.map(async (filename) => {
                            const fileData = await zip.file(filename).async('text');
                            return fileData;
                        });

                        const csvData = await Promise.all(csvPromises);
                        if(csvData !==undefined && csvData.length > 0){
                            this.tmpDataList = csvData[0].split("\n")
                        }
                    }

                })
                .catch((err) => console.log(err));
                
            }

        }
    };
</script>

<style scoped>
    .map-btn-toolbar {
        border-top: 1px solid #5e5e5e;
        border-bottom: 1px solid #5e5e5e;
        margin: 1rem 0;
        padding: 0.5rem 0;
        background-color: white;
    }

    svg { pointer-events: none; user-select: none;}
    svg * { pointer-events: all; user-select: none;}
    .shelf-input {
        font-size: 0.8rem;
        user-select: text !important;
    }
    .snap-check.custom-checkbox {
        padding-top: 0.5em;
        padding-left: 2em;
    }
    .map_store{
        padding: 2px 15px;
        background-color: #cecdcd;
        text-align: center;
    }
 
    .map_sub_count_left{
        float: left
    }
    .map_sub_count_right{
        margin-left: 10px;
        float: right;
    }
    .map-border{
    border-bottom: 1px solid #cecdcd;
    margin-right: 10px;
}
</style>
