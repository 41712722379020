<template>
<section>
    <b-alert variant="danger" dismissible v-model="alert.show">
        {{ $t(alert.message) }}
    </b-alert>

    <b-card no-body>
        <b-card-header header-tag="nav">
            <b-nav card-header tabs>
                <b-nav-item :active="navItem.isActive" @click="setNavItemActive(navItem)" v-bind:key="navItem.id" v-for="navItem of categories">{{ $t(navItem.label) }}
                </b-nav-item>
            </b-nav>
        </b-card-header>

        <b-card-body class="text-center">
            <div v-if="active_cat_id === 1">
                <b-tabs content-class="mt-4" pills small v-model="tabIndex">
                    <b-tab title="品目属性" active>
                        <settings-attribute :type="0" :key="componentKey"></settings-attribute>
                    </b-tab>
                    <b-tab title="端末側表示属性">
                        <settings-app-attribute :type="1" :key="componentKey"></settings-app-attribute>
                    </b-tab>
                    <b-tab title="作業属性">
                        <settings-attribute :type="2" :key="componentKey"></settings-attribute>
                    </b-tab>
                    <b-tab title="端末側表示属性2">
                        <settings-app-attribute-2 :type="3" :key="componentKey"></settings-app-attribute-2>
                    </b-tab>
                </b-tabs>
            </div>

            <b-table v-else striped responsive borderless :fields="headers" :items="gridData" @row-clicked="setSettingToEdit">
                <template v-slot:cell(delete)="data">

                    <b-form-checkbox :key="data.index" v-if="
                                data.item.functionId < 3 ||
                                data.item.functionId > 4
                            " v-model="selection" :value="data.item"></b-form-checkbox>

                </template>
                <template v-slot:cell(id)="data">
                    {{ data.item.id }}
                </template>
                <template v-slot:cell(functionId)="data">
                    <div v-if="data.value >= 0">
                        <span>{{ $t(`function_id.${data.value}.name`) }}</span>
                    </div>
                </template>
                <template v-slot:cell(encode_type)="data">
                    <div v-if="data.value.toString() !== ''">
                        <span>{{ $t(`encode_type_option.${data.value.toString()}`) }}</span>
                    </div>
                </template>
                <template v-slot:cell(name)="data">
                    <div>{{data.value}}</div>
                </template>
                <template v-slot:cell(showOrder)="data">
                    <b-form-select v-model="data.value" :options="menuDisplayOrderOptions" @change="changeMenusDisplayOrder(data, $event)" />
                </template>

            </b-table>

            <b-modal v-model="menuModel" size="s" @ok="createMenuFunction">
                <div><label style="font-weight:600">{{$t('menu_name')}}</label><input type="text" class="form-control" v-model="modalAlertChooseFunctionID.name" /></div>
                <div style="margin-top:15px"><label style="font-weight:600">{{$t('function_id_label')}}</label>
                    <b-form-select v-model="modalAlertChooseFunctionID.functionId" :options="functionOption" @change="changeMenuItem($event)"></b-form-select>
                </div>
                <div style="margin-top:15px" v-if="modalAlertChooseFunctionID.functionId === 1"><label style="font-weight:600">{{$t('status')}}</label>
                    <b-form-select v-model="modalAlertChooseFunctionID.stockStatus" :options="stockStatusOptions"></b-form-select>
                </div>
                <div style="margin-top:15px" v-if="modalAlertChooseFunctionID.functionId === 1"><label style="font-weight:600">{{$t('job_step')}}</label>
                    <b-form-select v-model="modalAlertChooseFunctionID.jobStep" :options="statusItemDefineOptions"></b-form-select>
                </div>
                <div v-if="modalAlertChooseFunctionID.functionId === 1 || modalAlertChooseFunctionID.functionId === 6" style="margin-top:15px"><label style="font-weight:600">{{$t('show_icon')}}</label>
                    <i v-bind:class="$t(`function_id.${modalAlertChooseFunctionID.functionId}.${modalAlertChooseFunctionID.showIcon}.logo_class`)"></i>
                    <b-form-select v-model="modalAlertChooseFunctionID.showIcon" :options="[
                            { value: 1, text: '1'},
                            { value: 2, text: '2' },
                            { value: 3, text: '3' },
                            { value: 4, text: '4' },
                            { value: 5, text: '5' },
                            { value: 6, text: '6' },
                            { value: 7, text: '7' }
                        ]">
                    </b-form-select>
                </div>
                <div style="margin-top:15px" v-if="modalAlertChooseFunctionID.functionId === 1"><label style="font-weight:600">{{$t('show_side')}}</label>
                    <b-form-select v-model="modalAlertChooseFunctionID.showSide" :options="[
                            { value: 0, text: 'WEBとAPP' },
                            { value: 1, text: 'WEB' },
                            { value: 2, text: 'APP' }
                        ]" @change="changeMenuShowSideItem($event)"></b-form-select>
                </div>
                <div style="margin-top:15px" v-if="(modalAlertChooseFunctionID.functionId === 1) && modalAlertChooseFunctionID.showSide !== 1"><label style="font-weight:600">{{$t('location_calculation')}}</label>
                    <b-form-select v-model="modalAlertChooseFunctionID.locationFlag" :options="[
                            { value: true, text: '位置タグあり、位置計算する' },
                            { value: false, text: '位置タグなし、位置計算しない' }
                        ]"></b-form-select>
                </div>
                <div style="margin-top:15px" v-if="(modalAlertChooseFunctionID.functionId === 1) && modalAlertChooseFunctionID.showSide !== 1"><label style="font-weight:600">{{$t('send_finish_flag')}}</label>
                    <b-form-select v-model="modalAlertChooseFunctionID.sendFinishFlag" :options="[
                            { value: true, text: 'true' },
                            { value: false, text: 'false' }
                        ]"></b-form-select>
                </div>
                <div style="margin-top:15px" v-if="(modalAlertChooseFunctionID.functionId === 1) && modalAlertChooseFunctionID.showSide !== 1"><label style="font-weight:600">{{$t('unexpected_tag_flag')}}</label>
                    <b-form-select v-model="modalAlertChooseFunctionID.unexpectedTagFlag" :options="[
                            { value: true, text: 'true' },
                            { value: false, text: 'false' }
                        ]"></b-form-select>
                </div>
                <div style="margin-top:15px" v-if="(modalAlertChooseFunctionID.functionId === 1) && modalAlertChooseFunctionID.showSide !== 1"><label style="font-weight:600">{{$t('map_mode')}}</label>
                    <b-form-select v-model="modalAlertChooseFunctionID.mapMode" :options="[
                            { value: true, text: 'true' },
                            { value: false, text: 'false' }
                        ]" @change="changeMenuMapMode($event)"></b-form-select>
                </div>
                <div style="margin-top:15px" v-if="modalAlertChooseFunctionID.mapMode === true"><label style="font-weight:600">{{$t('picking_rssi')}}</label>
                    <b-form-input v-model='modalAlertChooseFunctionID.pickingRssi' />
                </div>
            </b-modal>

            <b-modal title="Delete Menus" v-model="modalAlertDeleteMenu" size="s" @ok="deleteMenu">
                <p>Are you sure you want to delete the following menus:</p>
                <p>{{ selection.map((menu) => menu.id).join(", ") }}</p>
            </b-modal>
            <b-modal v-model="accountModal" size="xl">
                <b-container fluid>
                    <b-row class="my-1">
                        <b-col class="text-right">
                            <label>{{ $t("name") }}</label>
                        </b-col>
                        <b-col cols="4">
                            <b-form-input v-model='tenantId' disabled />
                        </b-col>
                        <b-col class="text-right">
                            <label>{{ $t("system_id") }}</label>
                        </b-col>
                        <b-col cols="4">
                            <b-form-input v-model='userToEdit.systemId' />
                        </b-col>
                    </b-row>
                    <b-row class="my-1">
                        <b-col class="text-right">
                            <label>{{ $t("encode_type") }}</label>
                        </b-col>
                        <b-col cols="4">
                            <b-form-select :options="[
                            { value: '0', text: 'default' },
                            { value: '1', text: 'EPC' },
                            { value: '2', text: 'SGTIN-96' },
                            { value: '3', text: 'FR' },
                            { value: '4', text: 'BAYCREWS' },
                            { value: '5', text: 'UA' }
                        ]" v-model='userToEdit.encode_type' />
                        </b-col>
                        <b-col class="text-right">
                            <label>{{ $t("remarks") }}</label>
                        </b-col>
                        <b-col cols="4">
                            <b-form-input v-model='userToEdit.remarks' />
                        </b-col>
                    </b-row>
                </b-container>

                <template v-slot:footer>
                    <b-button v-if="isCreateUser" class="btn green-rflocus" @click="createUser">
                        <i class="fa fa-plus-square"></i> {{ $t("button.create") }}
                    </b-button>

                    <b-container v-else>
                        <b-button class="btn green-rflocus" @click="updateUser">
                            <i class="fa fa-plus-square"></i> {{ $t("button.save") }}

                        </b-button>
                    </b-container>

                </template>
            </b-modal>

            <!-- <modal-setting> -->
            <b-modal v-model="menuSettingModal" size="xxl">
                <!-- header-->
                <b-container class="headerSettingModal">
                    <b-row>
                        <b-col cols="2">
                            <label>{{ $t("menu_id") }}</label>
                        </b-col>
                        <b-col cols="2">
                            <b-form-input :disabled="true" v-model='currentSettingsToEdit.id' />
                        </b-col>
                        <b-col cols="2" class="ml-5">
                            {{ $t("showing_name") }}
                        </b-col>
                        <b-col cols="4">
                            <b-button v-bind:key="currentSettingsToEdit.id" @click="setTextToEditModal(currentSettingsToEdit)">
                                {{currentSettingsToEdit.name}}
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
                <!-- !header-->

                <b-table responsive borderless :fields="fields" :items="settingToEdit" hover>

                    <template v-slot:cell(delete)="data">
                        <b-form-checkbox v-model="selection" :value="data.item"></b-form-checkbox>
                    </template>

                    <template v-slot:cell(source)="data">
                        <template v-if="data.item.isDisabled">
                            <template v-if="settingEditOptions.some(item=> item.value === data.value)">
                                <b-form-select v-model="data.value" :options="settingEditOptions" :disabled="data.item.isDisabled" @change="changeItem($event, data.item, data.field.key)">
                                </b-form-select>
                            </template>
                            <template v-else>
                                <b-form-input v-model="data.value" :disabled="data.item.isDisabled"></b-form-input>
                            </template>
                        </template>
                        <template v-else>
                            <b-row cols="1">
                                <b-col sm="10" v-if="data.item.showText">
                                    <b-form-input list="input-list" v-model="data.value" @input="changeItem($event, data.item, data.field.key)"></b-form-input>
                                </b-col>
                                <b-col sm="10" v-else>
                                    <b-form-select v-model="data.value" :options="settingEditOptions" :disabled="data.item.isDisabled" @change="changeSourceItem($event, data.item, data.field.key)">
                                    </b-form-select>
                                </b-col>
                                <b-col sm="1">
                                    <b-button @click="changeTextType(data.item)">
                                        <slot><i class="fa fa-exchange"></i></slot>
                                    </b-button>
                                </b-col>
                            </b-row>
                        </template>
                    </template>

                    <template v-slot:cell(name)="data">
                        <b-form-input class="setting_name_input" v-model='data.value' @input="changeItem($event, data.item, data.field.key)" />
                    </template>

                    <template v-slot:cell(searchOrder)="data">
                        <b-form-select class="setting_order_select" v-model="data.value" :options="listOrderSearchOptions" @change="changeListOrder($event, data.item, data.field.key)" />
                    </template>

                    <template v-slot:cell(listOrder)="data">
                        <template v-if="currentSettingsToEdit.functionId !== 3">
                            <b-form-select class="setting_order_select" v-model="data.value" :options="listOrderOptions" @change="changeListOrder($event, data.item, data.field.key)" />
                        </template>
                    </template>

                    <template v-slot:cell(detailOrder)="data">
                        <template v-if="currentSettingsToEdit.functionId !== 3">
                            <b-form-select class="setting_order_select" v-model="data.value" :options="listOrderOptions" @change="changeListOrder($event, data.item, data.field.key)" />
                        </template>
                    </template>
                </b-table>
                <template v-slot:footer>
                    <div class="w-100 d-flex justify-content-between">
                        <ButtonAdd @click="addRow"></ButtonAdd>
                        <div>
                            <ButtonSave @click="onclickItemUpdate"></ButtonSave>
                            <ButtonDelete @click="onclickItemDelete"></ButtonDelete>
                        </div>
                    </div>
                </template>
            </b-modal>

            <!-- <modal-editText> -->
            <b-modal v-model="editTextModal" size="s" @ok="updateNameFunction">
                <b-form-input v-model="textToEdit.value" />
            </b-modal>
        </b-card-body>
        <template v-slot:footer>
            <template v-if="active_cat_id === 2">
                <div class="w-100 d-flex justify-content-between">
                    <div>
                        <b-button class="btn green-rflocus mr-1" @click="addMenu()">
                            <i class="fa fa-plus-square"></i> {{ $t("button.create") }}
                        </b-button>
                        <b-button class="btn green-rflocus" @click="updateMenu()">
                            <i class="fa fa-plus-square"></i> {{ $t("button.update") }}
                        </b-button>
                    </div>
                    <ButtonDelete @click="showDeleteMenuAlert"></ButtonDelete>
                </div>
            </template>
            <template v-if="active_cat_id === 3">
                <b-button v-if="isCreateUser" class="btn green-rflocus" @click="addAccount()">
                    <i class="fa fa-plus-square"></i> {{ $t("button.create") }}
                </b-button>
                <b-button v-else class="btn green-rflocus" @click="updateAccount()">
                    <i class="fa fa-plus-square"></i> {{ $t("button.update") }}
                </b-button>
            </template>
        </template>
    </b-card>
</section>
</template>

<script>
//maybe I should replace this by simple axios call
import SettingsAttribute from "../../components/SettingsAttribute.vue";
import SettingsAppAttribute from "../../components/SettingsAppAttribute.vue";
import SettingsJobAttribute from "../../components/SettingsJobAttribute.vue";
import ButtonAdd from "../../components/buttons/ButtonAdd.vue";
import ButtonDelete from "../../components/buttons/ButtonDelete.vue";
import ButtonSave from "../../components/buttons/ButtonSave.vue";
import {
    API
} from "aws-amplify";
import AwsApi from "../../Api"
import * as queries from "../../graphql/locus_queries";
import * as mutations from "../../graphql/locus_mutations";
import global from "../../Global.vue";
import {
    BFormSelect
} from "bootstrap-vue-next";
import {
    v4 as uuidv4
} from "uuid"
import SettingsAppAttribute2 from '../../components/SettingsAppAttribute2.vue';

export default {
    components: {
        ButtonSave,
        ButtonDelete,
        ButtonAdd,
        SettingsAttribute,
        SettingsAppAttribute,
        SettingsAppAttribute2,
        SettingsJobAttribute,
        BFormSelect,
        SettingsAppAttribute2
    },
    computed: {
        attributes() {
            return [...this.attributesApi, ...this.attributesAdd];
        },
    },
    data() {
        return {
            tenantId: "",
            selection: [],
            categories: [{
                    id: 1,
                    label: "settings.attribute_settings",
                    isActive: false,
                    headers: [{
                        key: "merchandise",
                        label: this.$t("attributes.merchandise"),
                    }],
                },
                {
                    id: 2,
                    label: "settings.menu_settings",
                    isActive: false,
                    headers: [{
                            key: "delete",
                            label: this.$t("select"),
                        },
                        {
                            key: "id",
                            label: this.$t("menu_id")
                        },
                        {
                            key: "functionId",
                            label: `${this.$t("function")}ID`,
                        },
                        {
                            key: "name",
                            label: this.$t("showing_name")
                        },
                        {
                            key: "showOrder",
                            label: this.$t("display_order"),
                        },
                    ],
                },
                {
                    id: 3,
                    label: "settings.account_settings",
                    isActive: false,
                    headers: [{
                            key: "tenantId",
                            label: this.$t("tenant_id")
                        },
                        {
                            key: "systemId",
                            label: this.$t("system_id")
                        },
                        {
                            key: "encode_type",
                            label: this.$t("encode_type")
                        },
                        {
                            key: "remarks",
                            label: this.$t("remarks")
                        }
                    ],
                },
            ],
            headers: [],
            gridData: [],
            textToEdit: {
                id: 0,
                value: ""
            },
            //TODO
            alert: {
                show: false,
                message: ""
            },
            menuDisplayOrderOptions: [],
            modalAlertChooseFunctionID: {
                name: "",
                functionId: 0,
                stockStatus: null,
                locationFlag: null,
                showSide: 1,
                showIcon: 0,
                sendFinishFlag: null,
                unexpectedTagFlag: null,
                jobStep: null,
                mapMode: null,
                pickingRssi: null
            },
            active_cat_id: 1,
            userToEdit: {},
            isCreateUser: false,
            currentSettingsToEdit: {},
            fields: [{
                    key: 'delete',
                    label: this.$t("select"),
                },
                {
                    key: "source",
                    label: this.$t("attr_name")
                },
                {
                    key: "name",
                    label: this.$t("showing_name")
                },
                {
                    key: "searchOrder",
                    label: this.$t("search_order")
                },
                {
                    key: "listOrder",
                    label: this.$t("order")
                },
                {
                    key: "detailOrder",
                    label: this.$t("detail_order")
                }
            ],
            settingToEdit: [],
            settingEditOptions: [],
            listOrderOptions: [],
            listOrderSearchOptions: [],
            jobTable: ["Job", "JobDetail", "JobEpc", "ItemMaster"],
            stockTable: ["Stock", "ItemMaster"],
            itemMasterTable: "ItemMaster",
            defaultSystemId: "",
            menuId: -1,
            editTextModal: false,
            menuModel: false,
            modalAlertDeleteMenu: false,
            accountModal: false,
            menuSettingModal: false,
            tabIndex: 0,
            componentKey: 0,
            statusItemDefineOptions: undefined,
            stockStatusOptions: undefined,
            functionOption:[
                            { value: global.functionIdSTOCK, text: '在庫' },
                            { value: global.functionIdSTOCK2, text: '店頭・バックヤード在庫' },
                            { value: global.functionIdJOB, text: '作業' },
                            { value: global.functionIdLINK, text: '紐付け' },
                            { value: global.functionIdSEARCHMAP, text: 'マップ探索' },
                            { value: global.functionIdHISTORY, text: '履歴' }
                        ]
        };
    },
    async created() {
        this.tenantId = localStorage.getItem(global.tenantId)
        var currnetCategorie = this.categories[0]
        if (parseInt(this.$route.params.id) === -1) {
            currnetCategorie = this.categories[2]
        }
        currnetCategorie.isActive = true
        await this.fetchCategorieData(currnetCategorie);
    },
    watch: {
        tabIndex: function () {
            this.setAttributeActive()
        },
    },

    methods: {
        setAttributeActive() {
            this.componentKey += 1;
        },
        getCurrentActiveCategorie() {
            return this.categories.find(
                categorie => categorie.isActive === true
            );
        },
        async fetchData() {
            const categorie = this.getCurrentActiveCategorie();
            this.fetchCategorieData(categorie)
        },
        async fetchCategorieData(categorie) {
            switch (categorie.id) {
                case 1: {
                    this.getStatusItemDefines()
                    break;
                }
                case 2: {
                    return this.fetchMenuData(categorie).then(
                        (_) => (this.active_cat_id = categorie.id)
                    );
                }
                case 3: {
                    return this.fetchUserData(categorie).then(
                        (_) => (this.active_cat_id = categorie.id)
                    );
                }
            }
        },
        async fetchMenuData(categorie) {
            this.gridData = []
            this.headers = categorie.headers;
            const response = await AwsApi.graphql({
                query: queries.listMenus,
                variables: {
                    tenantId: this.tenantId
                }
            });
            if (response === undefined) {
                return
            }
            if (!this.categories[1].isActive) {
                return
            }

            this.gridData = response.data.listMenus.items.sort(function (a, b) {
                return a.showOrder - b.showOrder
            })

            this.menuDisplayOrderOptions = this.gridData.map((item, index) => ({
                value: index + 1,
                text: (index + 1).toString()
            }));

        },
        addMenu() {
            this.modalAlertChooseFunctionID = {
                name: "",
                functionId: 0,
                stockStatus: null,
                locationFlag: null,
                showSide: 1,
                showIcon: 0,
                sendFinishFlag: null,
                unexpectedTagFlag: null,
                jobStep: null,
                mapMode: null,
                pickingRssi: null
            }
            this.menuModel = true
        },
        updateMenu() {
            if (this.selection.length > 0) {
                this.modalAlertChooseFunctionID = {
                    id: this.selection[0].id,
                    name: this.selection[0].name,
                    functionId: this.selection[0].functionId,
                    stockStatus: this.selection[0].stockStatus,
                    locationFlag: this.selection[0].locationFlag,
                    showSide: this.selection[0].showSide,
                    showIcon: this.selection[0].icon,
                    sendFinishFlag: this.selection[0].sendFinishFlag,
                    unexpectedTagFlag: this.selection[0].unexpectedTagFlag,
                    jobStep: this.selection[0].jobStep,
                    mapMode: this.selection[0].mapMode,
                    pickingRssi: this.selection[0].pickingRssi,
                }
                this.menuModel = true
            }

        },
        showDeleteMenuAlert() {
            this.modalAlertDeleteMenu = true
        },
        setTextToEdit(cell) {
            this.textToEdit = {
                id: cell.item.id,
                value: cell.value
            };
            this.editTextModal = true
        },
        setTextToEditModal(cell) {
            this.textToEdit = {
                id: cell.id,
                value: cell.name
            };
            this.editTextModal = true
        },
        changeMenuItem(value) {
            switch (value) {
                case 0:
                case 5:
                    this.modalAlertChooseFunctionID.stockStatus = null
                    this.modalAlertChooseFunctionID.locationFlag = null
                    this.modalAlertChooseFunctionID.showSide = 1
                    this.modalAlertChooseFunctionID.showIcon = 0
                    this.modalAlertChooseFunctionID.sendFinishFlag = null
                    this.modalAlertChooseFunctionID.unexpectedTagFlag = null
                    this.modalAlertChooseFunctionID.jobStep = null
                    this.modalAlertChooseFunctionID.mapMode = null
                    this.modalAlertChooseFunctionID.pickingRssi = null
                    break;
                case 6:
                    this.modalAlertChooseFunctionID.stockStatus = null
                    this.modalAlertChooseFunctionID.locationFlag = null
                    this.modalAlertChooseFunctionID.showSide = 1
                    this.modalAlertChooseFunctionID.showIcon = 7
                    this.modalAlertChooseFunctionID.sendFinishFlag = null
                    this.modalAlertChooseFunctionID.unexpectedTagFlag = null
                    this.modalAlertChooseFunctionID.jobStep = null
                    this.modalAlertChooseFunctionID.mapMode = null
                    this.modalAlertChooseFunctionID.pickingRssi = null
                    break;
                case 1:
                    this.modalAlertChooseFunctionID.stockStatus = 0
                    this.modalAlertChooseFunctionID.locationFlag = true
                    this.modalAlertChooseFunctionID.showSide = 0
                    this.modalAlertChooseFunctionID.showIcon = 1
                    this.modalAlertChooseFunctionID.sendFinishFlag = false
                    this.modalAlertChooseFunctionID.unexpectedTagFlag = false
                    this.modalAlertChooseFunctionID.jobStep = 0
                    this.modalAlertChooseFunctionID.mapMode = false
                    this.modalAlertChooseFunctionID.pickingRssi = null
                    break;
                case 2:
                    this.modalAlertChooseFunctionID.stockStatus = null
                    this.modalAlertChooseFunctionID.locationFlag = null
                    this.modalAlertChooseFunctionID.showSide = 2
                    this.modalAlertChooseFunctionID.showIcon = 0
                    this.modalAlertChooseFunctionID.sendFinishFlag = null
                    this.modalAlertChooseFunctionID.unexpectedTagFlag = null
                    this.modalAlertChooseFunctionID.jobStep = null
                    this.modalAlertChooseFunctionID.mapMode = null
                    this.modalAlertChooseFunctionID.pickingRssi = null
                    break;
                case 7:
                    this.modalAlertChooseFunctionID.stockStatus = null
                    this.modalAlertChooseFunctionID.locationFlag = null
                    this.modalAlertChooseFunctionID.showSide = 2
                    this.modalAlertChooseFunctionID.showIcon = 4
                    this.modalAlertChooseFunctionID.sendFinishFlag = null
                    this.modalAlertChooseFunctionID.unexpectedTagFlag = null
                    this.modalAlertChooseFunctionID.jobStep = null
                    this.modalAlertChooseFunctionID.mapMode = null
                    this.modalAlertChooseFunctionID.pickingRssi = null
                    break;

            }
        },
        changeMenuShowSideItem(value) {
            switch (value) {
                case 1:
                    this.modalAlertChooseFunctionID.sendFinishFlag = null
                    this.modalAlertChooseFunctionID.unexpectedTagFlag = null
                    this.modalAlertChooseFunctionID.mapMode = null
                    this.modalAlertChooseFunctionID.pickingRssi = null
                    this.modalAlertChooseFunctionID.locationFlag = null
                    break;
                default:
                    this.modalAlertChooseFunctionID.sendFinishFlag = false
                    this.modalAlertChooseFunctionID.unexpectedTagFlag = false
                    this.modalAlertChooseFunctionID.mapMode = false
                    this.modalAlertChooseFunctionID.pickingRssi = null
                    this.modalAlertChooseFunctionID.locationFlag = true
            }
        },
        changeMenuMapMode(value) {
            if (value === false) {
                this.modalAlertChooseFunctionID.pickingRssi = null
            } else {
                this.modalAlertChooseFunctionID.pickingRssi = -100
            }
        },
        updateNameFunction() {
            AwsApi.checkSession(() => {
                const update = [];
                const updateMenu = {
                    id: this.textToEdit.id,
                    tenantId: this.tenantId,
                    name: this.textToEdit.value
                };
                update.push(API.graphql({
                    query: mutations.updateMenu,
                    variables: {
                        input: updateMenu
                    }
                }));
                Promise.all([...update]).then(() => {
                    this.fetchData();
                    this.currentSettingsToEdit.name = this.textToEdit.value
                    this.$bus.emit('patchMenuSettings');
                })
            }, this)
        },
        changeMenusDisplayOrder(cell, value) {
            AwsApi.checkSession(() => {
                const update = [];
                const updateMenu = {
                    id: cell.item.id,
                    tenantId: this.tenantId,
                    showOrder: value
                };
                update.push(API.graphql({
                    query: mutations.updateMenu,
                    variables: {
                        input: updateMenu
                    }
                }));
                Promise.all([...update]).then(() => {
                    this.fetchData();
                    this.$bus.emit('patchMenuSettings');
                })
            }, this)
        },
        async createMenuFunction() {
            AwsApi.checkSession(async () => {
                const create = [];
                const update = [];
                if (this.modalAlertChooseFunctionID.id) {
                    const updateMenu = {
                        id: this.modalAlertChooseFunctionID.id,
                        tenantId: this.tenantId,
                        name: this.modalAlertChooseFunctionID.name,
                        functionId: this.modalAlertChooseFunctionID.functionId,
                        stockStatus: this.modalAlertChooseFunctionID.stockStatus,
                        locationFlag: this.modalAlertChooseFunctionID.locationFlag,
                        group: this.tenantId,
                        showSide: this.modalAlertChooseFunctionID.showSide,
                        icon: this.modalAlertChooseFunctionID.showIcon,
                        sendFinishFlag: this.modalAlertChooseFunctionID.sendFinishFlag,
                        unexpectedTagFlag: this.modalAlertChooseFunctionID.unexpectedTagFlag,
                        jobStep: this.modalAlertChooseFunctionID.jobStep,
                        mapMode: this.modalAlertChooseFunctionID.mapMode,
                        pickingRssi: this.modalAlertChooseFunctionID.pickingRssi,

                    };
                    update.push(API.graphql({
                        query: mutations.updateMenu,
                        variables: {
                            input: updateMenu
                        }
                    }));
                } else {
                    const response = await API.graphql({
                        query: queries.listMenus,
                        variables: {
                            tenantId: this.tenantId
                        }
                    });
                    const maxId = response.data.listMenus.items.reduce((a, b) => {
                        return parseInt(a.id) > parseInt(b.id) ? a : b
                    })
                    const newMenu = {
                        id: (parseInt(maxId.id) + 1).toString(),
                        tenantId: this.tenantId,
                        name: this.modalAlertChooseFunctionID.name,
                        functionId: this.modalAlertChooseFunctionID.functionId,
                        stockStatus: this.modalAlertChooseFunctionID.stockStatus,
                        locationFlag: this.modalAlertChooseFunctionID.locationFlag,
                        group: this.tenantId,
                        showSide: this.modalAlertChooseFunctionID.showSide,
                        showOrder: response.data.listMenus.items.length + 1,
                        icon: this.modalAlertChooseFunctionID.showIcon,
                        sendFinishFlag: this.modalAlertChooseFunctionID.sendFinishFlag,
                        unexpectedTagFlag: this.modalAlertChooseFunctionID.unexpectedTagFlag,
                        jobStep: this.modalAlertChooseFunctionID.jobStep,
                        mapMode: this.modalAlertChooseFunctionID.mapMode,
                        pickingRssi: this.modalAlertChooseFunctionID.pickingRssi,
                    };
                    create.push(API.graphql({
                        query: mutations.createMenu,
                        variables: {
                            input: newMenu
                        }
                    }));
                }

                Promise.all([...create, ...update]).then(() => {
                    this.selection = [];
                    this.fetchData();
                    this.$bus.emit('patchMenuSettings');
                })
            }, this)
        },

        async deleteMenu() {
            AwsApi.checkSession(() => {
                const deletePro = []
                this.selection.forEach((item, index) => {
                    var deleteMenu = {
                        tenantId: this.tenantId,
                        id: item.id
                    }
                    deletePro.push(API.graphql({
                        query: mutations.deleteMenu,
                        variables: {
                            input: deleteMenu
                        }
                    }));
                })
                Promise.all([...deletePro]).then(() => {
                    this.selection = [];
                    this.fetchData()
                    this.$bus.emit('patchMenuSettings');
                })
            }, this)

        },
        async fetchUserData(categorie) {
            this.gridData = []
            this.headers = categorie.headers;
            const response = await AwsApi.graphql({
                query: queries.listTenants,
                variables: {
                    tenantId: this.tenantId
                }
            }, this);
            if (response === undefined) {
                return
            }
            if (!this.categories[2].isActive) {
                return
            }

            this.gridData = response.data.listTenants.items
            if (this.gridData.length === 0) {
                this.isCreateUser = true
            } else {
                this.isCreateUser = false
                this.defaultSystemId = this.gridData[0].systemId
            }
        },
        async setNavItemActive(navItemClicked) {
            this.categories.forEach(function (navItem) {
                navItem.isActive = false;
            });

            navItemClicked.isActive = true;
            await this.fetchCategorieData(navItemClicked);
            this.active_cat_id = navItemClicked.id;
        },
        async setSettingToEdit($row, record, evt) {
            if (this.active_cat_id === 2 && ($row.functionId === global.functionIdSTOCK || $row.functionId === global.functionIdJOB || $row.functionId === global.functionIdMASTER || $row.functionId === global.functionIdSTOCK2 || $row.functionId === global.functionIdHISTORY)) {
                this.currentSettingsToEdit = $row
                this.setMenuSettingTitle()
                this.getMenuModalListData()
                this.getSourceOption()
                this.menuSettingModal = true
            }

        },
        setMenuSettingTitle() {
            if (this.currentSettingsToEdit.functionId !== global.functionIdMASTER) {
                this.fields = [{
                        key: 'delete',
                        label: this.$t("select"),
                    },
                    {
                        key: "source",
                        label: this.$t("attr_name")
                    },
                    {
                        key: "name",
                        label: this.$t("showing_name")
                    },
                    {
                        key: "searchOrder",
                        label: this.$t("search_order")
                    },
                    {
                        key: "listOrder",
                        label: this.$t("order")
                    },
                    {
                        key: "detailOrder",
                        label: this.$t("detail_order")
                    }
                ]
            } else {
                this.fields = [{
                        key: 'delete',
                        label: this.$t("select"),
                    },
                    {
                        key: "source",
                        label: this.$t("attr_name")
                    },
                    {
                        key: "name",
                        label: this.$t("showing_name")
                    },
                    {
                        key: "searchOrder",
                        label: this.$t("search_order")
                    }
                ]
            }

        },
        async getMenuModalListData() {
            const response = await AwsApi.graphql({
                query: queries.queryMenuConfigsByMenuId,
                variables: {
                    tenantId: this.tenantId,
                    menuId: {
                        eq: this.currentSettingsToEdit.id
                    }
                }
            });
            if (response === undefined) {
                return
            }
            const resSort = response.data.queryMenuConfigsByMenuId.items.sort(function (a, b) {
                if (a.listOrder === null) {
                    a.listOrder = 999
                } else if (b.listOrder === null) {
                    b.listOrder = 999
                } else if (a.detailOrder === null) {
                    a.detailOrder = 999
                }
                if (b.detailOrder === null) {
                    b.detailOrder = 999
                }
                return a.listOrder - b.listOrder || a.detailOrder - b.detailOrder

            });
            this.settingToEdit = resSort.map(item => ({
                ...item,
                searchOrder: item.searchOrder === null ? -1 : item.searchOrder,
                listOrder: item.listOrder === 999 ? -1 : item.listOrder,
                detailOrder: item.detailOrder === 999 ? -1 : item.detailOrder,
                isDisabled: true,
                showText: false

            }));
            this.setOrderListOptions(this.settingToEdit.length)
        },
        async getSourceOption() {
            const response = await AwsApi.graphql({
                query: queries.listItemDefines,
                variables: {
                    tenantId: this.tenantId
                }
            });
            if (response === undefined) {
                return
            }
            this.settingEditOptions = response.data.listItemDefines.items.filter(item =>
                    (item.source !== 'ItemMaster.itemCode' &&
                        (((this.currentSettingsToEdit.functionId === global.functionIdSTOCK || this.currentSettingsToEdit.functionId === global.functionIdSTOCK2) && (this.stockTable.includes(item.sourceTable))) ||
                            ((this.currentSettingsToEdit.functionId === global.functionIdJOB || this.currentSettingsToEdit.functionId === global.functionIdHISTORY) && (this.jobTable.includes(item.sourceTable))))) ||
                    (item.sourceTable === this.itemMasterTable && this.currentSettingsToEdit.functionId === global.functionIdMASTER))

                .map(item => ({
                    value: item.source,
                    text: item.name
                }));

            if (this.currentSettingsToEdit.functionId === global.functionIdSTOCK2) {
                this.settingEditOptions = [...this.settingEditOptions, {
                    value: "storeCount",
                    text: "店頭在庫数"
                }, {
                    value: "storeLocations",
                    text: "店頭棚"
                }, {
                    value: "backyardCount",
                    text: "バックヤード在庫数"
                }, {
                    value: "backyardLocations",
                    text: "バックヤード棚"
                }, {
                    value: "daysInfo",
                    text: "滞留日数"
                }]
            }
        },
        addAccount() {
            this.userToEdit = {}
            this.accountModal = true
        },
        updateAccount() {
            if (this.gridData.length > 0) {
                this.userToEdit = {
                    systemId: this.gridData[0].systemId,
                    encode_type: this.gridData[0].encode_type,
                    remarks: this.gridData[0].remarks,
                }
            }

            this.accountModal = true
        },
        updateUser() {
            AwsApi.checkSession(() => {
                const update = [];
                const updateParam = {
                    tenantId: this.tenantId,
                    systemId: this.userToEdit.systemId,
                    encode_type: this.userToEdit.encode_type,
                    remarks: this.userToEdit.remarks,
                };
                update.push(API.graphql({
                    query: mutations.updateTenant,
                    variables: {
                        input: updateParam
                    }
                }));
                Promise.all([...update]).then(() => {
                    if (this.defaultSystemId !== this.userToEdit.systemId) {
                        API.graphql({
                            query: mutations.updateTenantSystemId,
                            variables: {
                                systemId: this.userToEdit.systemId
                            }
                        })
                    }

                    this.fetchData();
                    this.$bus.emit('patchMenuSettings');
                    this.accountModal = false
                })
            }, this)
        },
        createUser() {
            AwsApi.checkSession(() => {
                const create = [];
                const createParam = {
                    systemId: this.userToEdit.systemId,
                    encode_type: this.userToEdit.encode_type,
                    remarks: this.userToEdit.remarks,
                };
                create.push(API.graphql({
                    query: mutations.initTenant,
                    variables: {
                        tenant: createParam
                    }
                }));
                Promise.all([...create]).then(() => {
                    this.fetchData();
                    this.$bus.emit('patchMenuSettings');
                    this.accountModal = false
                })
            }, this)
        },
        addRow() {
            const settings = [...this.settingToEdit]
            settings.push({
                id: uuidv4(),
                source: "",
                name: "",
                searchOrder: -1,
                listOrder: -1,
                detailOrder: -1,
                toCreate: true,
                showText: false
            });
            this.settingToEdit = settings
            this.setOrderListOptions(this.settingToEdit.length)
        },
        onclickItemDelete() {
            if (this.selection.length === 0) {
                return
            }
            let selection = [...this.selection]
            AwsApi.checkSession(() => {
                const deletePro = []
                selection.forEach((item, index) => {
                    if (item.toCreate) {
                        this.settingToEdit = this.settingToEdit.filter(e => e.id != item.id)
                    } else {
                        const deleteItem = {
                            tenantId: this.tenantId,
                            id: item.id
                        }
                        deletePro.push(API.graphql({
                            query: mutations.deleteMenuConfig,
                            variables: {
                                input: deleteItem
                            }
                        }));
                    }
                })
                Promise.all([...deletePro]).then(() => {
                    this.selection = []
                    this.getMenuModalListData()
                })
            }, this)
        },
        setOrderListOptions(size) {
            let listOrder = [{
                value: -1,
                text: "--"
            }, {
                value: 0,
                text: "0"
            }];

            for (let i = 1; i <= size; i++) {
                listOrder.push({
                    value: i,
                    text: "" + i
                });
            }
            this.listOrderOptions = listOrder

            let searchListOrder = [{
                value: -1,
                text: "--"
            }];

            for (let i = 1; i <= size; i++) {
                searchListOrder.push({
                    value: i,
                    text: "" + i
                });
            }
            this.listOrderSearchOptions = searchListOrder
        },
        onclickItemUpdate() {
            AwsApi.checkSession(() => {
                const create = []
                const update = []
                this.settingToEdit.forEach((item, index) => {
                    if (item.source != null) {
                        if (item.toCreate) {
                            const createItem = {
                                group: this.tenantId,
                                tenantId: this.tenantId,
                                menuId: this.currentSettingsToEdit.id,
                                source: item.source,
                                name: item.name,
                                searchOrder: item.searchOrder === -1 ? null : item.searchOrder,
                                listOrder: item.listOrder === -1 ? null : item.listOrder,
                                detailOrder: item.detailOrder === -1 ? null : item.detailOrder,
                            }
                            create.push(API.graphql({
                                query: mutations.createMenuConfig,
                                variables: {
                                    input: createItem
                                }
                            }));
                        } else if (item.toUpdate) {
                            const updateItem = {
                                tenantId: this.tenantId,
                                group: this.tenantId,
                                id: item.id,
                                name: item.name,
                                searchOrder: item.searchOrder === -1 ? null : item.searchOrder,
                                listOrder: item.listOrder === -1 ? null : item.listOrder,
                                detailOrder: item.detailOrder === -1 ? null : item.detailOrder,
                            }
                            update.push(API.graphql({
                                query: mutations.updateMenuConfig,
                                variables: {
                                    input: updateItem
                                }
                            }));
                        }
                    }
                })
                Promise.all([...create, ...update]).then(() => {
                    this.selection = []
                    this.getMenuModalListData()
                    this.menuSettingModal = false
                })
            }, this)

        },
        changeItem(value, cell, key) {
            cell[key] = value;
            cell.toUpdate = true
        },
        changeSourceItem(value, cell, key) {
            cell[key] = value
            const current = this.settingEditOptions.find(option => option.value === value)
            cell.name = current.text
            cell.toUpdate = true
        },
        changeTextType(cell) {
            cell.showText = !cell.showText
        },
        changeListOrder(value, cell, key) {
            this.changeItem(value, cell, key)

            setTimeout(_ => {
                const listOrder = this.settingToEdit.filter(item => item[key] !== -1).sort(function (a, b) {
                    if (a.id === cell.id && a[key] === b[key]) {
                        return -1
                    }
                    return a[key] - b[key]
                });
                var order = 1
                listOrder.forEach((item, index) => {
                    if (item[key] !== 0) {
                        item[key] = order++
                    }
                })
                this.settingToEdit = this.settingToEdit.map(item => {
                    const data = {
                        ...item,
                        toUpdate: true
                    }
                    const current = listOrder.find(f => f.id === item.id)
                    if (current !== undefined) {
                        data[key] = current[key]
                    }
                    return data
                });
            }, 100)

        },
        async getStatusItemDefines() {
            const response = await AwsApi.graphql({
                query: queries.listItemDefines,
                variables: {
                    tenantId: this.tenantId,
                }
            });
            if (response === undefined) {
                return
            }

            if (response.data.listItemDefines.items !== null) {
                const stock = response.data.listItemDefines.items.filter(item => item.source === "Stock.status")
                const job = response.data.listItemDefines.items.filter(item => item.source === "Job.status")
                if (stock !== undefined && stock.length > 0) {
                    this.stockStatusOptions = Object.entries(JSON.parse(stock[0].items)).map(([key, value]) => ({
                        text: value,
                        value: key
                    }))
                } else {
                    this.setStockDefaultStatus()
                }
                if (job !== undefined && job.length > 0) {
                    this.statusItemDefineOptions = Object.entries(JSON.parse(job[0].items)).map(([key, value]) => ({
                        text: value,
                        value: key
                    }))
                } else {
                    this.setJobDefaultStatus()
                }

            } else {
                this.setStockDefaultStatus()
                this.setJobDefaultStatus()
            }
        },
        setStockDefaultStatus() {
            this.stockStatusOptions = [{
                    value: 0,
                    text: '在庫'
                },
                {
                    value: -1,
                    text: '出庫'
                }
            ]
        },
        setJobDefaultStatus() {
            this.statusItemDefineOptions = [{
                    value: 0,
                    text: '予定(default)'
                },
                {
                    value: 1,
                    text: '作業中'
                },
                {
                    value: 2,
                    text: '作業済'
                }
            ]
        }
    },
};
</script>

<style>
.text_japanese_oneline {
    white-space: nowrap;
}

.setting_order_select {
    width: 60px;
}

.setting_name_input {
    width: 300px;
}
</style>
